import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import en_common from './en/common.json';
import th_common from './th/common.json';
import cn_common from './cn/common.json';

import en_sellerprofile from './en/sellerprofile.json';
import th_sellerprofile from './th/sellerprofile.json';
import cn_sellerprofile from './cn/sellerprofile.json';

export const defaultNS = 'common';
export const resources = {
    th: {
        common: th_common,
        sellerprofile: th_sellerprofile,
    },
    en: {
        common: en_common,
        sellerprofile: en_sellerprofile,
    },
    cn: {
        common: cn_common,
        sellerprofile: cn_sellerprofile,
    },
} as const;

// const ln = JSON.parse(localStorage.getItem("9asset_language") || '{"language":"en"}');

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        supportedLngs: ['en', 'th', 'cn'],
        detection: {
            order: ['querystring', 'localStorage'],
            lookupQuerystring: 'lng',
            lookupLocalStorage: 'i18nextLng',
        },
        fallbackLng: 'th',
        ns: ['common'],
        defaultNS,
        resources,
        react: {
            useSuspense: false
        }
    });
