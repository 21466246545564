import { faFacebookF, faGoogle, faLine, faWeixin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, styled } from '@mui/material';

const GoogleButton = styled(Button)(() => ({
    // background: '#4286f4',
    // color: '#fff',
    justifyContent: 'center',
}));

const FacebookButton = styled(Button)(() => ({
    background: '#4267B2',
    color: '#fff',
    justifyContent: 'center'
}));

const WechatButton = styled(Button)(() => ({
    background: '#09B83E',
    color: '#fff',
    justifyContent: 'center'
}));

const LineButton = styled(Button)(() => ({
    background: '#00C300',
    color: '#fff',
    justifyContent: 'center'
}));

const buttons = {
    google: GoogleButton,
    facebook: FacebookButton,
    line: LineButton,
    wechat: WechatButton
}

export const SocialButton = (props: any) => {
    const social = props.social as string || null;
    const SocialBtn = (social === 'google' || social === 'facebook' || social === 'line' || social === 'wechat' )? buttons[social] : Button;
    const getIcon = (social: string | null) => {
        const _getIcon = (social: string | null) => {
            switch (social) {
                case 'google':
                    return faGoogle;
                case 'facebook':
                    return faFacebookF;
                case 'line':
                    return faLine;
                case 'wechat':
                    return faWeixin;
                default:
                    return null;
            }
        }

        const icon = _getIcon(social);
        return icon ? <FontAwesomeIcon icon={(icon)} size="lg" style={{  marginTop: '2px', marginBottom: '2px', marginRight: '10px' }} /> : '-';
    };
    const children = <>{getIcon(social)}{props.children}</>
    
    return (
        <SocialBtn
            {...props}
            variant="outlined"
            fullWidth
            color="info"
            sx={{ fontSize: '1rem', textTransform: 'capitalize' }}
        >{children}</SocialBtn>
    );
}