import { Component } from "react";
import {
    Box,
    TextField,
    Button, Backdrop, CircularProgress,  Grid, Card, CardContent
} from '@mui/material';
import { RouteComponentProps, withRouter } from "../with-router";
import { LoginHeader } from "../components/loginHeader";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../firebase';
import { WithTranslation, withTranslation } from "react-i18next";

// const ForgetPasswordContainer = styled(Paper)({
//     maxWidth: 400,
//     height: 250,
//     display: 'block',
//     margin: 'auto',
//     marginTop: 30,
//     position: 'relative',
//     padding: '1.5rem 1.5rem'
// });

export interface ForgetPasswordProps extends RouteComponentProps, WithTranslation {
}
export interface ForgetPasswordState {
    isLoading: boolean;
    sent: boolean;
    email: string;
    isHeadlessMode: boolean;
}
export class ForgetPassword extends Component<ForgetPasswordProps, ForgetPasswordState> {

    constructor (props: ForgetPasswordProps | Readonly<ForgetPasswordProps>) {
        super(props);
        this.state = {
            isLoading: false,
            sent: false,
            email: '',
            isHeadlessMode: false,
        }
    }

    componentDidMount(): void {
        const params = new URLSearchParams(window.location.search);
        this.setState({ isHeadlessMode: params.get('isHeadlessMode') === 'true' });
    }

    onNextClicked = async () => {
        this.setState({ isLoading: true });
        if (!this.state.email) {
            this.setState({ isLoading: false });
            return;
        }
        try {

            await sendPasswordResetEmail(auth, this.state.email);
            console.log('Password reset email sent')
        } catch (error) {
            console.log(error);

        }
        this.setState({ sent: true });
        this.setState({ isLoading: false });
    }

    onLoginClicked = async () => {
        this.props.navigate(`/${this.props.location.search}`);
    }

    onEmailChanged = (e: any) => {
        this.setState({ email: e.target.value });
    }

    renderInput () {
        return (<>
            <Box mt={3} mb={1} textAlign="center">
                { this.props.t('enterYourEmail') }
            </Box>
            <Box mb={1} mx={2}  display="flex" justifyContent="center">
                <TextField type="email" fullWidth variant="outlined" size="small" label="Email" margin="dense" onChange={this.onEmailChanged} value={this.state.email}></TextField>
            </Box>
            <Box mt={1} style={{ textAlign: "center", marginTop: '15px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={this.onNextClicked}
                    sx={{ background: '#f4762a', color: '#fff', paddingLeft: '25px', paddingRight: '25px'}}
                >
                    { this.props.t('next') }
                </Button>
            </Box>
        </>);
    }

    renderMessage () {
        return (<>
            <Box mt={3} mb={3} textAlign="center">
                { this.props.t('resentResetPassword') }
            </Box>
            <Box mt={3} style={{ textAlign: "center", marginTop: '15px' }}>
                <Button variant="contained" color="primary" size="medium" onClick={this.onLoginClicked} style={{ background: '#f4762a', color: '#fff'}}>{ this.props.t('goToLogin') }</Button>
            </Box>
        </>);
    }

    render () {
        const showBackdrop = this.state.isLoading;
        const detail = this.state.sent ? this.renderMessage() : this.renderInput();
        return (<>
        <Grid container sx={{ maxWidth: 600, margin: '0 auto', padding: '4px' , height: '100%'}}>
            <Grid item xs={12} container justifyContent="center" alignItems="center">
                <Card
                    elevation={ this.state.isHeadlessMode ? 0 : 1 }
                    sx={{ 
                        margin: '10 auto',
                        marginTop: this.state.isHeadlessMode ? '' : '-40%',
                        width: '100%'
                    }}
                >
                    <LoginHeader title={this.props.t('resetPassword')} subtitle={''} />
                    <CardContent style={{ padding: '16px' }}>
                        {/* <Typography className="form-title" variant="h4" component="h4"  align="left">Reset Password</Typography> */}
                        { detail }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <Backdrop className="loading-backdrop" open={showBackdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
        </>);
    }
}
export default withTranslation('common')(withRouter(ForgetPassword));