import { ThemeProvider } from "@mui/material";
import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation
} from "react-router-dom";


import './App.css';
import GICPIAP from "./gcip-iap";
import theme from './theme';
import './locales/index';
import Login from "./Login/Login";
import Register from "./Register/Register";
import Logout from "./Logout/Logout";
import UserMgmt from "./UserMgmt/UserMgmt";
import ForgetPassword from "./ForgetPassword/ForgetPassword";
import { useAppDispatch } from "./store/hooks";
import { setAction } from "./store/mainSlice";
import { useTranslation } from "react-i18next";

const App  = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  
  useEffect(() => {
    switch(location.pathname) {
      case '/':
        dispatch(setAction('signin'));
        break;
      case '/register':
        dispatch(setAction('signup'));
        break;
      default:
        dispatch(setAction(null));
        break;
    }
    console.log(location.pathname);
  }, [location.pathname]);
  
  return (
    <ThemeProvider theme={theme}>
      {/* <Router basename={`${process.env.PUBLIC_URL}`}> */}
        <Routes >
          <Route path="/">
            <Route index element={<Login />} />
            <Route path="/?redirect=:redirect" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/usermgmt" element={<UserMgmt />} />
            <Route path="/forgetpassword" element={<ForgetPassword />} />
          </Route>
          <Route path="/gicp" element={<GICPIAP />} />
        </Routes>
      {/* </Router> */}
    </ThemeProvider>
  );
    
}

export default App;