import { useEffect, useState } from "react";
import { User } from "firebase/auth";
import { AuthContext } from "./AuthContext";
import { auth } from "./index";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { fetchUserInfo, mainSlice } from "../store/mainSlice";


export const AuthProvider = (props: any) => {
  const [user, setUser] = useState<User|null>(null);
  const dispatch = useAppDispatch();
  const { action } = useAppSelector((state) => state.mainReducer);

  useEffect(() => {
    const unsub = auth.onIdTokenChanged((firebaseUser) => {
      console.log('AuthProvider - onIdTokenChanged: ', firebaseUser);
      setUser(firebaseUser);
    });

    return () => {
      unsub && unsub();
    }
  }, []);

  useEffect(() => {
    // dispatch(mainSlice.actions.setUser(user));
    if (user) {
      // updateUserInfo(user);
    } else {
      localStorage.removeItem('9asset_token');
      localStorage.removeItem('9asset.userinfo');
    }
  }, [user])

  const updateUserInfo = async (user: User) => {
    const token = await user.getIdToken();
    const userInfo = await dispatch(fetchUserInfo(token));
    
    if (userInfo && action === 'signin') {
      // window.parent?.postMessage({
      //     source: 'login',
      //     target: '9assetApp',
      //     type: 'logged-in',
      //     data: userInfo
      // }, '*');

      console.log('send logged-in')
    }
    // localStorage.setItem('9asset_token', token);
    // const userInfo = (await axios.get(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users?from=authprovider`, { headers: { 'Authorization': `Bearer ${token}`} })).data;
    localStorage.setItem(`9asset.userinfo`, JSON.stringify(userInfo));
  }

  return <AuthContext.Provider value={user}>{props.children}</AuthContext.Provider>;
}