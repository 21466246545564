import { Component } from "react";

import { auth } from "../firebase";
import { Backdrop, Box, Button, Card, CardActions, CardContent, Chip, 
    CircularProgress, FormControl, Grid, IconButton, 
    InputAdornment, InputLabel, OutlinedInput, 
    Typography 
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { RouteComponentProps, withRouter } from "../with-router";
import { LoginHeader } from "../components/loginHeader";
import { applyActionCode, checkActionCode, confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { WithTranslation, withTranslation } from "react-i18next";

// const ActionContainer = styled(Paper)({
//     maxWidth: 400,
//     height: 300,
//     display: 'block',
//     margin: 'auto',
//     marginTop: 30,
//     position: 'relative'
// });

export interface UserMgmtState {
    mode: 'resetPassword' | 'recoverEmail' | 'verifyEmail' | string | null,
    oobCode: string | null,
    apiKey: string | null,
    continueUrl?: string | null,
    lang?: string,

    verifyingStatus: 'verifying' | 'verified' | 'failed' | null;
    resetPasswordError: 'expired' | 'invalid' | 'disable' | 'notfound' | 'completed' | null;

    showLoading: boolean,
    email: string | null,

    showPassword: boolean;
    showConfirmpassword: boolean;
    password: string;
    confirmPassword: string;
}

export interface UserMgmtProps extends RouteComponentProps, WithTranslation {
    
}

export class UserMgmt extends Component<UserMgmtProps, UserMgmtState> {

    constructor (props: Readonly<UserMgmtProps> | any) {
        super(props);
        this.state = {
            mode: null,
            oobCode: null,
            apiKey: null,
            continueUrl: null, 
            lang: 'en',

            verifyingStatus: null,
            resetPasswordError: null,

            showLoading: false,
            email: null,
            showPassword: false,
            showConfirmpassword: false,
            password: '',
            confirmPassword: ''
        };
    }

    async componentDidMount () {
        const queries = this.getQueries();
        this.setState({
            mode: queries.mode,
            oobCode: queries.oobCode,
            apiKey: queries.apiKey,
            continueUrl: queries.continueUrl,
            lang: queries.lang
        }, async () => {
            if (!this.state.oobCode) {
                return;
            }

            if (this.state.mode === 'verifyEmail') {
                this.setState({ ...this.state, verifyingStatus: 'verifying', showLoading: true });
                try {
                    await applyActionCode(auth, this.state.oobCode);
                    console.log(auth.currentUser?.uid);
                    this.setState({ ...this.state, verifyingStatus: 'verified', showLoading: false });
                } catch (error: any) {
                    if (error.code === 'auth/invalid-action-code') {
                        this.setState({ ...this.state, verifyingStatus: 'failed', showLoading: false });
                    }
                }
            } else if (this.state.mode === 'resetPassword') {
                try {
                    this.setState({ ...this.state, resetPasswordError: null, showLoading: true });
                    const result = await verifyPasswordResetCode(auth, this.state.oobCode);
                    this.setState({ ...this.state, email: result, resetPasswordError: null, showLoading: false });
                } catch (error: any) {
                    if (error.code === 'auth/expired-action-code') {
                        this.setState({ ...this.state, resetPasswordError: 'expired', showLoading: false });
                    } else if (error.code === 'auth/invalid-action-code') {
                        this.setState({ ...this.state, resetPasswordError: 'invalid', showLoading: false });
                    } else if (error.code === 'auth/user-disabled') {
                        this.setState({ ...this.state, resetPasswordError: 'disable', showLoading: false });
                    } else if (error.code === 'auth/user-not-found') {
                        this.setState({ ...this.state, resetPasswordError: 'notfound', showLoading: false });
                    } 

                    console.log(error);
                }
            } else if (this.state.mode === 'recoverEmail') {
                await checkActionCode(auth, this.state.oobCode);
                // const restoredEmail = info['data']['email'];

                // Revert to the old email.
                return applyActionCode(auth, this.state.oobCode);

            } else if (this.state.mode === 'verifyAndChangeEmail') {
                this.setState({ ...this.state, verifyingStatus: 'verifying', showLoading: true });
                try {
                    await applyActionCode(auth, this.state.oobCode);
                    console.log(auth.currentUser?.uid);
                    this.setState({ ...this.state, verifyingStatus: 'verified', showLoading: false });
                } catch (error: any) {
                    if (error.code === 'auth/invalid-action-code') {
                        this.setState({ ...this.state, verifyingStatus: 'failed', showLoading: false });
                    }
                }
            }
        });
    }

    getQueries = () => {
        const params = new URLSearchParams(window.location.search);
        const mode = params.get('mode') || null;
        return {
            mode: mode,
            oobCode: params.get('oobCode') as string || null,
            apiKey: params.get('apiKey') as string || null,
            continueUrl: params.get('continueUrl') as string || undefined,
            lang: params.get('lang') as string || 'en',

            verifyingStatus: mode === 'verifyEmail' ? 'verifying' : null
        };
    }

    getVerifyStatus = () => {
        const statusMsg = this.state.verifyingStatus === 'verifying' ? 'Verifying...'
                            : (this.state.verifyingStatus === 'verified' ? 'Verify Completed' 
                                : (this.state.verifyingStatus === 'failed' ? 'Try verifying your email again' : 'Unknown Status'));
        const statusDesc = this.state.verifyingStatus === 'failed' ? 'Your request to verify your email has expired or the link has already been used' : '';
        return { statusMsg, statusDesc };
    }

    renderVerifiyEmail = () => {
        const { statusMsg, statusDesc } = this.getVerifyStatus();
        return (<>
        <CardContent style={{ padding: '16px' }}>
            <Box textAlign="center">
                <Typography variant="h6">Email Verification</Typography>
            </Box>
            <Box textAlign="center">
                <Typography variant="body1">{ statusMsg }</Typography>
            </Box>
            <Box px={3} mt={2} textAlign="left">
                <Typography variant="body2">{ statusDesc }</Typography>
            </Box>
        </CardContent>
        <CardActions>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Button
                    variant="contained"
                    style={{ background: '#f4762a', color: '#fff'}} 
                    size="small" onClick={this.onCloseRequested}>
                    { this.state.verifyingStatus === 'verified' ? 'Continue' : 'Close'}
                </Button>
            </Grid>
        </CardActions>
        </>);
    }

    renderPasswordPanel = () => {
        if (this.state.resetPasswordError === 'expired') {
            return (<>
            <CardContent style={{ padding: '16px' }}>
                <Box textAlign="center" px={4}>
                    <Typography variant="body1">{ this.props.t('emailExpired') }</Typography>
                </Box>
            </CardContent>
            <CardActions>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Button
                        variant="contained"
                        style={{ background: '#f4762a', color: '#fff'}} 
                        size="medium" onClick={this.onForgetPasswordequested}>Request Again</Button>
                </Grid>
            </CardActions>
            </>);
        } else if (this.state.resetPasswordError === 'invalid') {
            return (<>
            <CardContent style={{ padding: '16px' }}>
                <Box textAlign="center" px={4}>
                    <Typography variant="body1">Password reset code is invalid or has been already used. <br />Please submit your email to reset again.</Typography>
                </Box>
                </CardContent>
            <CardActions>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Button
                        variant="contained"
                        style={{ background: '#f4762a', color: '#fff'}} 
                        size="medium" onClick={this.onForgetPasswordequested}>Request Again</Button>
                </Grid>
            </CardActions>
            </>);
        } else if (this.state.resetPasswordError === 'disable') {
            return (<>
            <CardContent style={{ padding: '16px' }}>
                <Box textAlign="center" px={4}>
                    <Typography variant="body1">Password reset code has been disabled<br />Please submit your email to reset again.</Typography>
                </Box>
                </CardContent>
            <CardActions>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Button
                        variant="contained"
                        style={{ background: '#f4762a', color: '#fff'}} 
                        size="medium" onClick={this.onForgetPasswordequested}>Request Again</Button>
                </Grid>
            </CardActions>
            </>);
        } else if (this.state.resetPasswordError === 'notfound') {
            return (<>
            <CardContent style={{ padding: '16px' }}>
                <Box textAlign="center" px={4}>
                    <Typography variant="body1">There is no your email in our system.<br />Please register to login.</Typography>
                </Box>
                </CardContent>
            <CardActions>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Button
                        variant="contained"
                        style={{ background: '#f4762a', color: '#fff'}} 
                        size="medium" onClick={this.onRegisterRequested}>Register</Button>
                </Grid>
            </CardActions>
            </>);
        } else if (this.state.resetPasswordError === 'completed') {
            return (<>
            <CardContent style={{ padding: '16px' }}>
                <Box textAlign="center" px={4}>
                    <Typography variant="body1">{ this.props.t('passwordReset') }</Typography>
                </Box>
            </CardContent>
            <CardActions>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Button
                        variant="contained"
                        style={{ background: '#f4762a', color: '#fff'}} 
                        size="medium" onClick={this.onLoginRequested}>Login</Button>
                </Grid>
            </CardActions>
            </>);
        } else if (this.state.email) {
            return (<>
            <CardContent style={{ padding: '16px' }}>
                <Box textAlign="center">
                    <Typography variant="body1">Please enter new password to reset the account: </Typography>
                </Box>
                <Box textAlign="center">
                    <Chip label={this.state.email} variant="outlined"></Chip>
                </Box>
                <Box px={5}>
                    <FormControl variant="outlined" fullWidth margin="dense">
                        <InputLabel htmlFor="outlined-adornment-password" margin="dense">Password</InputLabel>
                        <OutlinedInput label="Password" fullWidth type={this.state.showPassword ? "text" : "password"}
                            id="outlined-adornment-passwords"
                            endAdornment={
                                <InputAdornment position="end">
                                <IconButton onClick={this.handleClickShowPassword} onMouseDown={this.handleMouseDownPassword}>
                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                onChange={this.onPasswordChanged}
                                value={this.state.password} />
                    </FormControl>
                </Box>
                <Box px={5}>
                    <FormControl variant="outlined" fullWidth margin="dense">
                        <InputLabel htmlFor="outlined-adornment-c-password" margin="dense">Confirm Password</InputLabel>
                        <OutlinedInput label="Confirm Password" fullWidth type={this.state.showConfirmpassword ? "text" : "password"}
                            id="outlined-adornment-c-passwords"
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={this.handleClickShowConfirmPassword} onMouseDown={this.handleMouseDownPassword}>
                                    {this.state.showConfirmpassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                            onChange={this.onConfirmPasswordChanged}
                            value={this.state.confirmPassword} />
                    </FormControl>
                </Box>
                </CardContent>
            <CardActions>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Button
                        variant="contained"
                        style={{ background: '#f4762a', color: '#fff'}} 
                        size="medium" onClick={this.onSavePasswordRequested}>Save</Button>
                </Grid>
            </CardActions>
            </>);
        } else {
            return (<></>);
        }
    }

    renderResetPassword = () => {
        const password = this.renderPasswordPanel();
        return (<>
            {/* <Box textAlign="center">
                <Typography variant="h6">Password Reset</Typography>
            </Box> */}
            { password }
        </>);
    }

    renderMode = () => {
        switch (this.state.mode) {
            case 'verifyEmail' :
                return this.renderVerifiyEmail();
            case 'verifyAndChangeEmail' :
                return this.renderVerifiyEmail();
            case 'resetPassword' :
                return this.renderResetPassword();
            default:
                return (<></>)
        }
    }

    getTitle = () => {
        switch (this.state.mode) {
            case 'verifyEmail' :
                return 'Email Verification';
            case 'verifyAndChangeEmail' :
                return 'Email Verification';
            case 'resetPassword' :
                return 'Reset Password';
            default:
                return this.state.mode || '';
        }
    }

    render () {
        const renderItem = this.renderMode();
        const showLoading = this.state.showLoading;
        return (<>
            <Grid container sx={{ maxWidth: 600, margin: '0 auto', padding: '4px', height: '100%' }}>
                <Grid item xs={12} container justifyContent="center" alignItems="center">
                    <Card elevation={1} sx={{ margin: '10px auto', width: '100%', marginTop: '-40%' }} >
                        <LoginHeader title={this.getTitle()} subtitle={''} />
                        { renderItem }
                    </Card>
                </Grid>
            </Grid>
            <Backdrop className="loading-backdrop" open={showLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>);
    }

    onCloseRequested  = () => {
        if (this.state.mode === 'verifyEmail') {
            this.props.navigate('/register?step=userinfo')
        }
    }

     onSavePasswordRequested = async () => {
        if (!this.state.email || !this.state.password || !this.state.oobCode) {
            return;
        }
        this.setState({ showLoading: true });
        try {
            await confirmPasswordReset(auth, this.state.oobCode, this.state.password);
            this.setState({ resetPasswordError: 'completed', showLoading: false });
        } catch (error) {

        }
    }

    handleClickShowPassword = () => {
        this.setState({ showPassword : !this.state.showPassword});
    };

    handleClickShowConfirmPassword = () => {
        this.setState({ showConfirmpassword: !this.state.showConfirmpassword});
    };

    handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    onPasswordChanged = (e: any) => {
        this.setState({ password: e.target.value });
    }

    onConfirmPasswordChanged = (e: any) => {
        this.setState({ confirmPassword: e.target.value });
    }

    onForgetPasswordequested = () => {
        this.props.navigate('/forgetpassword');
    }

    onRegisterRequested = () => {
        this.props.navigate('/register');
    }

    onLoginRequested = () => {
        this.props.navigate('/');
    }
}

export default withTranslation('common')(withRouter(UserMgmt));