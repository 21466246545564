import { Component } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// Import GCIP/IAP module.
import * as ciap from 'gcip-iap';
// interface AuthenticationHandler {
//   languageCode?: string | null;
//   getAuth(apiKey: string, tenantId: string | null): FirebaseAuth;
//   startSignIn(auth: FirebaseAuth, match?: SelectedTenantInfo): Promise<UserCredential>;
//   selectTenant?(projectConfig: ProjectConfig, tenantIds: string[]): Promise<SelectedTenantInfo>;
//   completeSignOut(): Promise<void>;
//   processUser?(user: User): Promise<User>;
//   showProgressBar?(): void;
//   hideProgressBar?(): void;
//   handleError?(error: Error | CIAPError): void;
// }
import { FirebaseAuth, UserCredential } from "@firebase/auth-types";
// import { render } from '@testing-library/react';

import {
    Backdrop,
    Box, Button, CircularProgress, Grid, TextField
} from '@mui/material';
// import EditIcon from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom';

// const expectedApiKey = 'AIzaSyC8Yi6jarHdVaGs9bkwZhfPvQ_do0eZvuE';
const firebaseConfig =  {
    // databaseURL: "https://PROJECT_ID.firebaseio.com",
    // measurementId: "G-MEASUREMENT_ID",
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY ?? "AIzaSyAM8TSg7vnMI1IlbJLGsc3dBfbsKwM-vdQ" }`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTHDOMAIN  ?? "assets-dev-c64cb.firebaseapp.com"}`,
    projectId:`${process.env.REACT_APP_FIREBASE_PROJECTID ? process.env.REACT_APP_FIREBASE_PROJECTID:  "assets-dev-c64cb"}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_BUCKET  ?? "assets-dev-c64cb.appspot.com"}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_SENDERID ?? "1080628476581"}`,
    appId: `${process.env.REACT_APP_FIREBASE_APPID  ?? "1:1080628476581:web:622922cfed202fa64e6b4c"}`
}

console.log(firebaseConfig.projectId);
console.log(process.env.REACT_APP_FIREBASE_PROJECTID);

interface AppState {
    mode?: 'SIGN_IN' | 'SIGN_IN_WITH_EMAIL' | 'SIGN_UP_WITH_EMAIL' |
        'SIGN_OUT' | 'PROGRESS_BAR' | 'SELECT_TENANT' | 'NONE';
    navbar?: any;
    auth?: any;
    onSignInSuccess?: any;
    // alertParams?: AlertParameters;
    // selectTenant?: SelectTenantParameters;
    // signIn?: SignInParameters;
    // signInWithEmail?: SignInWithEmailParameters;
    // signUpWithEmail?: SignUpWithEmailParameters;
  }

const SignInUI = (props: any) => {
     
    const phoneNo = props.phoneNo;
    const showBackdrop = props.isLoading;
    return (
        <>
        <Box mt={3}>
            <TextField label="Phone or Email" variant="outlined" margin="dense" fullWidth onChange={props.onPhoneNoChanged} value={phoneNo}></TextField>
        </Box>
        <Box mt={1} style={{ textAlign: "center", marginTop: '15px' }}>
            <Button variant="contained" color="primary" size="medium" onClick={props.onNextClicked} style={{ background: '#f4762a', color: '#fff'}}>Next</Button>
        </Box>
        <div className="hr-line">
            <div className="hr-line-1"></div>
            <span>or</span>
            <div className="hr-line-1"></div>
        </div>
        <Box mt={1}>
            <p className="additional-sign-text">Sign in with</p>
        </Box>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Button variant="contained" style={{ background: '#4286f4', color: '#fff'}} size="medium" fullWidth onClick={props.onGoogleClicked} >Google</Button>
                {/* <GoogleButton label="Google " onClick={() => { console.log('Google button clicked') }} className="google-btn" /> */}
            </Grid>
            <Grid item xs={4}>
                <Button variant="contained" color="primary" style={{ background: '#4267B2', color: '#fff'}} size="medium" fullWidth >Facebook</Button>
            </Grid>
            <Grid item xs={4}>
                <Button variant="contained" style={{ background: '#06bc0b', color: '#fff'}} size="medium" fullWidth >We Chat</Button>
            </Grid>
        </Grid>

        <p className="text-center my-3 buttom-panel">
            <Link to="/register" className="link-text" >
                Sign up here
            </Link>{" | "}
            <Link to="/forgetpassword" className="link-text" >
                Forgot Password?
            </Link>
        </p>
        <Backdrop className="loading-backdrop" open={showBackdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
        </>
    );
}

class AuthHandlerPage extends Component<{}, AppState> implements ciap.AuthenticationHandler {
    private progressBarTimer: any;
    private ciapInstance: any;
    private config: any;
  
    constructor(props: {}) {
      super(props);
      this.progressBarTimer = null;
      this.state = {
        mode: 'NONE',
        // navbar: {
        //   link: `/${window.location.search}`,
        //   originalUrl: 'N/A',
        // },
      };
    }
  
    componentDidMount() {
        this.config = firebaseConfig;
        // const authHandlerImplementation = new AuthHandler();
        // const authHandlerImplementation = ....
        try {
            this.ciapInstance = new ciap.Authentication(this);
            const p = this.ciapInstance.start();
            this.ciapInstance.getOriginalURL().then((originalUrl: string | null) => {

                console.log('originalUrl: ', originalUrl)
                this.setState({
                  navbar: {
                    link:  `/${window.location.search}`,
                    originalUrl: originalUrl || 'N/A',
                  },
                })
            })
            .catch(() => {
                // Suppress getOriginalURL() errors as this currently only works for multi-tenant
                // use case only.
                this.setState({
                    navbar: {
                    link: `/${window.location.search}`,
                    originalUrl:  'N/A',
                    },
                })
            });
            return p;
        } catch(err) {

        }
    }

    private updateError(error: {code?: string, message?: string, retry?: any} | null) {
        // const modifiedState: AppState = {
        //     alertParams: {
        //     code: (error && error.code) || undefined,
        //     message: (error && error.message) || undefined,
        //     retry: (error && error.retry) || undefined,
        //     },
        //     // Keep existing values for the rest of the state.
        //     mode: this.state.mode,
        //     navbar: this.state.navbar,
        //     signIn: this.state.signIn,
        //     signInWithEmail: this.state.signInWithEmail,
        //     signUpWithEmail: this.state.signUpWithEmail,
        //     selectTenant: this.state.selectTenant,
        // };
        // this.setState(modifiedState);

        console.log(error);
    }

    selectTenant?(projectConfig: ciap.ProjectConfig, tenantIds: string[]): Promise<ciap.SelectedTenantInfo> {
        return new Promise((resolve, reject) => {
            // Show UI to select the tenant.
            console.log('// Show UI to select the tenant.')
            // renderSelectTenant(
            //     tenantIds,
            //     // On tenant selection.
            //     (selectedTenantId) => {
            //       resolve({
            //         tenantId: selectedTenantId,
            //         // If associated provider IDs can also be determined,
            //         // populate this list.
            //         providerIds: [],
            //         // If email is available, populate this field too.
            //         email: undefined,
            //       });
            //     });
          });
        
    }

    getAuth(apiKey: string, tenantId: string | null): FirebaseAuth {

        console.log('getAuth: ', apiKey, tenantId)
        let auth = null;
        // Make sure the expected API key is being used.
        if (apiKey !== this.config.apiKey) {
            throw new Error('Invalid project!');
        }
        try {
            auth = firebase.app(tenantId || undefined).auth();
            // Tenant ID should be already set on initialization below.
        } catch (e) {
            // Use different App names for every tenant. This makes it possible to have
            // multiple users signed in at the same time (one per tenant).
            const app = firebase.initializeApp(this.config, tenantId || '[DEFAULT]');
            auth = app.auth();
            // Set the tenant ID on the Auth instance.
            auth.tenantId = tenantId || null;
        }

        return auth as any; 
      }
    
    startSignIn(auth: FirebaseAuth, match?: ciap.SelectedTenantInfo): Promise<UserCredential> {

        console.log('sign in start')
        return new Promise((resolve, reject) => {
            

            this.setState({
                auth: auth,
                mode: 'SIGN_IN',
                onSignInSuccess: (userCredential: UserCredential) => {
                    resolve(userCredential);
                }
            })
            // return false;
            // Show UI to sign-in or sign-up a user.
            // $('#sign-in-form').on('submit', (e) => {
            //   const email = $('#email').val();
            //   const password = $('#password').val();
            //   // Example: ask user for email and password.
            //   // The method of sign in may have already been determined from the
            //   // selectedTenantInfo object.
            // const email = 'pong@cw.in.th';
            // const password = '123456';

            //   auth.signInWithEmailAndPassword(email, password)
            //     .then((userCredential) => {

            //         console.log('signInWithEmailAndPassword: ', userCredential)
            //       resolve(userCredential);
            //     })
            //     .catch((error) => {
            //       // Show error message.

            //       console.log('error: ', error);
            //       reject()
            //     });
            // });

          
            
        });
    };
    //  processUser?(user: User): Promise<User> {
    //      console.log('processUser: ', user)
    //      return new Promise((resolve, reject) => {

    //      })
    //  }
    showProgressBar?(): void {
        console.log('show progress bar')
    }
    hideProgressBar?(): void {
        console.log('hide progress bar')
    }

    handleError?(error: Error | ciap.CIAPError): void {
        console.log('handleError ', error) 
    }

    completeSignOut(): Promise<void> {

        return Promise.resolve();
    }

    onGoogleClicked() {
        console.log('onGoogleClicked');
        // this.state.auth.signInWithRedirect(new firebase.auth.GoogleAuthProvider())
        // .catch((error: any) => {
        //   this.updateError(error);
        // });

        firebase.auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then((userCredential) => {
            this.state.onSignInSuccess(userCredential);
        }).catch((error) => {
            // Handle Errors here.
            // var errorCode = error.code;
            // var errorMessage = error.message;
            // The email of the user's account used.
            // var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            // var credential = error.credential;
            // ...
            console.log(error);
        });
    }

    renderCiapComponent() {
        console.log('renderCiapComponent: ', this.state)
        switch (this.state.mode) {
            case 'SIGN_IN':
                // const signIn = this.state.signIn as SignInParameters;
                return (
                    <div>
                        <SignInUI {...this.props}
                            onGoogleClicked = {this.onGoogleClicked.bind(this)}
                        />
                    </div> 
                )
            case 'SIGN_IN_WITH_EMAIL':
                return <div>{this.state.mode}</div> 
               
            // case 'SIGN_UP_WITH_EMAIL':
              
            // case 'SELECT_TENANT':
               
            // case 'SIGN_OUT':

            // case 'PROGRESS_BAR':
            //     return <div></div>;
            default:
                return <div></div>;
        }
    }

    render() {
        return (
            <div>
                {`version ${process.env.REACT_APP_VERSION}`}
                {this.renderCiapComponent()}
            </div>
        )
    }
}





export default AuthHandlerPage;