import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { User } from "firebase/auth";

interface MainState {
  action: 'signin' | 'signup' | 'signout' | null;
  isHeadlessMode: boolean;
  isLoading: boolean;
  user: User | null;
  userInfo: any | null;
}

const initialState: MainState = {
  action: null,
  isLoading: false,
  isHeadlessMode: false,
  user: null,
  userInfo: null,
};

export const fetchUserInfo = createAsyncThunk(
  'users/userInfo',
  async (token: string | null, { getState }) => {
    const state = getState() as MainState;
    if (!token) {
      token = await state.user?.getIdToken() || null;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users?from=loginMainState`,
        { headers: { 'Authorization': `Bearer ${token}`} }
      );
      return response.data;
    } catch {
      return null;
    }
  }
);

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setHeadlessMode: (state: MainState) => {
      state.isHeadlessMode = true;
    },

    setUserInfo: (state: MainState, action) => {
      state.userInfo = action.payload;
    },
    setAction: (state: MainState, action) => {
      state.action = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchUserInfo.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userInfo = action.payload;
    })
    .addCase(fetchUserInfo.rejected, (state) => {
      state.isLoading = false;
      state.userInfo = null;
    });
  }
});

export const { setAction } = mainSlice.actions;
export const mainReducer = mainSlice.reducer;