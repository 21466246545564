import { createTheme, colors } from '@mui/material';

const theme = createTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#f4762a'
    },
    secondary: {
        contrastText: '#ffffff',
        main: '#6cac19'
    },
    error: {
        contrastText: '#ffffff',
        main: '#e57373'
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    }
  },
});

export default theme;