import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Card, CardActions, CardContent, 
    Grid, Link, Select, MenuItem, TextField, 
    Button, Chip, Backdrop, CircularProgress,
    Checkbox, FormControlLabel, Typography, Divider,
    FormHelperText,
    IconButton,
    InputAdornment,
    CardHeader
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import {
    getAuth,
    FacebookAuthProvider,
    GoogleAuthProvider,
    RecaptchaVerifier,
    signInWithPopup,
    signInWithEmailAndPassword, 
    ConfirmationResult,
    signInWithPhoneNumber,
    sendSignInLinkToEmail,
    isSignInWithEmailLink,
    signInWithEmailLink,
    fetchSignInMethodsForEmail,
    EmailAuthProvider,
    setPersistence,
    browserLocalPersistence,
    browserSessionPersistence,
    getAdditionalUserInfo,
    Unsubscribe,
    updateEmail,
    sendEmailVerification,
    linkWithPhoneNumber,
    UserCredential,
    AuthErrorCodes
} from 'firebase/auth';
import { app, auth } from "../firebase";

import pk from '../../package.json';
import axios from "axios";
import _ from "lodash";
import { DividerLine } from "../components/DividerLine";
import { SocialButton } from "../components/SocialButton";
import { LanguageInput } from "../components/LanguageInput";
import { LoginHeader } from "../components/loginHeader";
import { Replay, Visibility, VisibilityOff } from "@mui/icons-material";
import { ActionCodeSettings } from "@firebase/auth-types";
import OtpInput from "../components/OtpInput";
import MuiPhoneNumber from "material-ui-phone-number";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { fetchUserInfo, mainSlice } from "../store/mainSlice";
import { stat } from "fs";

enum AuthMode {
    PhoneNo = 'phone',
    EmailWithPassword =  'password',
    EmailLink = 'emailLink',
    Google = 'google',
}


export interface LoginProps {

}

export interface LoginState {

    isLoading: boolean;
    isEditing: boolean;
    errorText: string | null;
    passwordErrorText: string | null;
    nameError: string | null;
    lastnameError: string | null;

    userInfo: {
        name: string;
        lastname: string;
    }
    phoneInfo: {
        value: string,
        dialCode: string
    }
    email: string;

    step: number;
    phoneNo: string;
    password: string;
    authMode: AuthMode | null;
    showPassword: boolean;
    rememberMe: boolean;
    confirmResult: ConfirmationResult | null;
    timeInterval: any;
    // unsubscribe: Unsubscribe | null;
    userCredential: UserCredential | null;

    phoneNoError: string | null;
    otpError: string | null;
    emailError: string | null;
    otp: string;

    isNewUser: boolean;
    isAcceptPrivacy: boolean,
    acceptanceRequired: boolean,
    emailVerified: boolean | null,
    // auth: Auth;
}

const initialState: LoginState = {
    isLoading: false,
    isEditing: false,
    errorText: null,
    passwordErrorText: null,
    nameError: null,
    lastnameError: null,
    isAcceptPrivacy: false,
    acceptanceRequired: false,

    userInfo: {
        name: '',
        lastname: '',
    },
    phoneInfo: {
        value: '',
        dialCode: ''
    },
    email: '',

    step: 0,
    authMode: null, // AuthMode.Google,
    phoneNo: '',
    password: '',
    rememberMe: false,
    showPassword: false,
    confirmResult: null,
    timeInterval: null,

    userCredential: null,
    phoneNoError: null,
    otpError: null,
    emailError: null,
    otp: '',
    isNewUser: false,
    emailVerified: null,
}

export const Login = (props: LoginProps) => {

    const { t, i18n } = useTranslation('common');
    const [params,] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { isHeadlessMode, userInfo } = useAppSelector((state) => state.mainReducer);

    const [state, setState] = useState<LoginState>(initialState);

    useEffect(() => {
        (window as any).recaptchaVerifier = new RecaptchaVerifier('recaptcha-container',{ 'size': 'invisible' }, auth);
        componentDidMount();
    }, []);

    useEffect(() => {
        if (state.authMode === AuthMode.PhoneNo && state.phoneNo) {
            verifyPhoneNo();
        }
    }, [state.phoneNo, state.authMode]);

    // useEffect(() => {
    //     if (auth && auth.currentUser) {
    //         redirectToDefaultPage();
    //     }
    // }, [auth.currentUser]);

    const componentDidMount = async () => {

        if (auth && auth.currentUser) {
            redirectToDefaultPage();
        }
        else {
            const email = window.localStorage.getItem('emailForSignIn');
            if (email) {
                try {
                    const credential = await signInWithEmailLink(getAuth(app), email, window.location.href);
                    if (credential.user) {
                        redirectToDefaultPage();
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    window.localStorage.removeItem('emailForSignIn');
                }
            } else {

                const mode = params.get('authmode');
                const phone = params.get('phone');
                if(mode && phone) {
                    setState((prev) => ({ ...prev, authMode: AuthMode.PhoneNo, phoneNo: decodeURIComponent(phone), isLoading: true }));
                }
            }
        }

        params.get('isHeadlessMode') === 'true' && dispatch(mainSlice.actions.setHeadlessMode());

        window.parent?.postMessage({
            source: 'login',
            target: '9assetApp',
            type: 'loaded'
        }, '*');
    }

    const getMobileNo = (input: string | null) => {
        if (!input) {
            return null;
        }
        const inter = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/g;
        if (inter.test(input)) {
            return input;
        }

        const thai = /0{1}(6|8|9){1}([0-9]{8})/g;
        const matches = thai.exec(input);
        if(matches) {
            return `+66${matches[1]}${matches[2]}`;
        } else if(/^([0-9]{8,14})$/g.test(input)) {
            return `${input}`;
        } else {
            return null;
        }
    }

    const isValidateEmail = (email: string | null) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const getUserInfoStatus = async (type: 'email' | 'phoneno', value: string) => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/status/${type}/${value}`);
           
            return response.data.status;
        } catch (error) {
            debugger;
        }
    }

    const onEdit = () => {
        setState((prev) => ({ ...prev, step: 0 }));
    }

    const onLangChanged = async (event: any) => {
        await i18n.changeLanguage(event.target.value);
        localStorage.setItem("9asset_language", JSON.stringify({ language: i18n.language }));
    }

    const onNextClicked = async () => {
        // state.unsubscribe && state.unsubscribe();
        setState((prev) => ({ ...prev, isLoading: true }));
        const phoneNo = getMobileNo(state.phoneNo);
        if (phoneNo !== null) {
            setState((prev) => ({
                ...prev,
                phoneNo: phoneNo,
                authMode: AuthMode.PhoneNo,
                errorText: null
            }));
        } else if (isValidateEmail(state.phoneNo)) {
            setState((prev) => ({ ...prev, errorText: null }));
            selectLoginMethod();
        } else {
            setState((prev) => ({
                ...prev,
                errorText: t('invalidFormat'),
                isLoading: false
            }));
        }
    }

    const onPhoneNoChanged = (value: any) => {
        setState((prev) => ({ ...prev, phoneNo: value.target.value }));
    }

    const onGoogleLoginRequest = async () => {
        // state.unsubscribe && state.unsubscribe();
        setState((prev) => ({ ...prev, isLoading: true, authMode: AuthMode.Google }));
        
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: 'select_account'
        });
        try {
            const result = await signInWithPopup(auth, provider);
            setState((prev) => ({ ...prev, userCredential: result }));

            if(result.user) {
                const userInfo = getAdditionalUserInfo(result);
             
                if (userInfo?.isNewUser) {

                    setState((prev) => ({ ...prev, isNewUser: true }));
                    const [name, lastname] = (result.user.displayName || '').split(' ', 2);

                    const data = {
                        name: name || '',
                        lastname: lastname || '',
                        phoneNo: result.user.phoneNumber,
                        email: result.user.email
                    };
                    const token = await result.user.getIdToken();
                    const header = {
                        headers: { 'Authorization': `Bearer ${token}`}
                    };
                    await axios.put(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/`, data, header);

                    setState((prev) => ({ ...prev, step: 2, isLoading: false, authMode: AuthMode.Google, userInfo: { name: name, lastname: lastname } }));
                } else {

                    let user: any = null;
                    try {
                        const token = await result.user.getIdToken();
                        const header = {
                            headers: { 'Authorization': `Bearer ${token}`}
                        };
                        const response = await axios.get(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/`, header);
                        user = response.data;
                        if (!user.phoneNumber) {
                            setState((prev) => ({ ...prev, step: 2, isLoading: false, authMode: AuthMode.Google }));
                        } else {
                            redirectToDefaultPage();
                        }
                    } catch {
                        user = null;
                        redirectToDefaultPage();
                    }
                    
                }
            }
        } catch (error: any) {
            console.log('error: ', error)
            setState((prev) => ({ ...prev, isLoading: false }));
        } finally {
            
        }
    }

    const onFacebookLoginRequest = async () => {
        setState((prev) => ({ ...prev, isLoading: true }));
        const provider = new FacebookAuthProvider();
        try {
            const userCredential = await signInWithPopup(auth, provider);
            if(userCredential.user) {
                redirectToDefaultPage();
            }
        } catch (error) {

        } finally {
            setState((prev) => ({ ...prev, isLoading: false }));
        }
    }

    const onLoginRequest = () => {
        if (state.authMode === AuthMode.PhoneNo) {
            loginByOtp();
        } else if (state.authMode === AuthMode.EmailWithPassword) {
            loginWithEmailAndPassword();
        }
    }

    const applyPersistance = () => {
        return setPersistence(auth, state.rememberMe ? browserLocalPersistence : browserSessionPersistence );
    }

    const loginByOtp = async () => {
        const confirmResult = state.confirmResult;
        if (!confirmResult) {
            return;
        }

        setState((prev) => ({ ...prev, passwordErrorText: null }));
        try {
            setState((prev) => ({ ...prev, isLoading: true }));
            const result = await confirmResult.confirm(state.otp);
            if (result.user) {
                const userInfo = getAdditionalUserInfo(result);
                if (userInfo?.isNewUser) {

                    setState((prev) => ({ ...prev, isNewUser: true }))
                    const [name, lastname] = (result.user.displayName || '').split(' ', 2);

                    const data = {
                        name: name || '',
                        lastname: lastname || '',
                        phoneNo: result.user.phoneNumber,
                        email: result.user.email
                    };
                    const token = await result.user.getIdToken();
                    const header = {
                        headers: { 'Authorization': `Bearer ${token}`}
                    };
                    await axios.put(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/`, data, header);

                    // TODO: Get User Info
                    setState((prev) => ({ ...prev, step: 2 }));
                } else {
                    redirectToDefaultPage();
                }
            }
           
        } catch(error: any) {
            if (error.code === 'auth/invalid-verification-code') {
                setState((prev) => ({ ...prev, passwordErrorText: t('invalidOtp') }));
            } else {
                setState((prev) => ({ ...prev, passwordErrorText: error.code || error }));
            }
        } finally {
            setState((prev) => ({ ...prev, isLoading: false }));
        }
        
    }

    const onPasswordChanged = (value: any) => {
        setState((prev) => ({ ...prev, password: value.target.value }));
    }

    const onOtpChanged = (otp: string) => {
        setState((prev) => ({ ...prev, otp: otp }));
    }

    const onOtp2Changed = (otp: string) => {
        setState((prev) => ({ ...prev, otp: otp }));
    }

    const onClickShowPassword = () => {
        setState((prev) => ({ ...prev, showPassword : !prev.showPassword }));
    };

    const onRememberChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({ ...prev, rememberMe: event.target.checked }));
    }

    const onMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const loginWithEmailAndPassword = async () => {
        console.log('loginByEmail')
        setState((prev) => ({ ...prev, isLoading: true }));
        if (state.phoneNo && state.password) {
            try {
                // let doublebase64 = Buffer.from(state.password).toString('base64');
                // doublebase64 = Buffer.from(doublebase64).toString('base64');
                await applyPersistance();
                const credential = await signInWithEmailAndPassword(auth, state.phoneNo, state.password);
                if (credential.user) {
                    // const token = await credential.user.getIdToken();
                    // const userInfo = (await axios.get(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users?from=loginWithEmailAndPassword`, { headers: { 'Authorization': `Bearer ${token}`} })).data;
                    // localStorage.setItem(`9asset.userinfo`, JSON.stringify(userInfo));
                    redirectToDefaultPage();
                }
                setState((prev) => ({
                    ...prev,
                    isLoading: false,
                    passwordErrorText: null
                }));
            } catch (error: any) {
                if (error?.code === 'auth/wrong-password') {
                    setState((prev) => ({ ...prev, passwordErrorText: t('invalidPassword') }));
                } if (error?.code === 'auth/wrong-password') {
                    setState((prev) => ({ ...prev, emailError: t('invalidEmail') }))
                } else {
                    console.log(`Error on Login By Email: ${error}`);
                    setState((prev) => ({ ...prev, passwordErrorText: error.message || error }));
                }
            } finally {
                setState((prev) => ({
                    ...prev,
                    isLoading: false 
                }));
            }
        } else {
            setState((prev) => ({
                ...prev,
                isLoading: false,
                passwordErrorText: 'please insert password' 
            })); 
        }
    }

    const onResentEmailRequest = async () => {
        setState((prev) => ({ ...prev, isLoading: true }));
        try {
            const actionCodeSettings = {
                url: window.location.href,
                handleCodeInApp: true,
            } as ActionCodeSettings;

            await applyPersistance();
            await sendSignInLinkToEmail(auth, state.phoneNo, actionCodeSettings);
            setState((prev) => ({ ...prev, errorText: null }));            

        } catch (error) {
        } finally {
            setTimeout(() => {
                setState((prev) => ({ ...prev, isLoading: false }));
            }, 2000);
        }

    }

    const onSigninWithLinkrequest = async () => {
        isSignInWithEmailLink(auth, window.location.href);
        await applyPersistance();
        await signInWithEmailLink(auth, state.phoneNo);
    }

    const onResendOtpRequest = async () => {
        try {
            setState((prev) => ({ ...prev, isLoading: true }));
            // const provider = new PhoneAuthProvider(auth);
            // const verificationId = await provider.verifyPhoneNumber(`${state.phoneNo.value}`, (window as any).recaptchaVerifier);
            // setState((prev) => ({ ...prev, verificationId: verificationId, loading: false });

            const result = await signInWithPhoneNumber(auth, state.phoneNo, (window as any).recaptchaVerifier);
            setState((prev) => ({ ...prev, confirmResult: result, isLoading: false }));
        } catch (error) {
            setState((prev) => ({ ...prev, isLoading: false }));
        }
    }

    const onResendOtp2Request = async () => {
        try {
            setState((prev) => ({ ...prev, isLoading: true }));
            // const provider = new PhoneAuthProvider(auth);
            // const verificationId = await provider.verifyPhoneNumber(`${state.phoneNo.value}`, (window as any).recaptchaVerifier);
            // setState((prev) => ({ ...prev, verificationId: verificationId, loading: false });
            await applyPersistance();
            const result = await signInWithPhoneNumber(auth, state.phoneInfo.value, (window as any).recaptchaVerifier);;
            setState((prev) => ({ ...prev, confirmResult: result, isLoading: false }));
        } catch (error) {
            setState((prev) => ({ ...prev, isLoading: false }));
        }
    }

    const selectLoginMethod = async () => {
        const email = state.phoneNo;
        const methods = await fetchSignInMethodsForEmail(auth, email);
        if (methods.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) {
            setState((prev) => ({
                ...prev,
                authMode: AuthMode.EmailWithPassword,
                step: state.step + 1,
                errorText: null,
                isLoading: false }));
        } else if (methods.includes(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD)) {
            verifyEmailByLink();
        } else if (methods.includes(GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD)) {

            onGoogleLoginRequest();
        } else if (methods.includes(FacebookAuthProvider.FACEBOOK_SIGN_IN_METHOD)) {
            onFacebookLoginRequest();
        } else {
            setState((prev) => ({
                ...prev,
                isLoading: false,
                errorText: t('emailNotFound')
            }));
        }
    }

    const verifyEmailByLink = async () => {
        try {
            const actionCodeSettings = {
                url: window.location.href,
                handleCodeInApp: true,
            } as ActionCodeSettings;

            await sendSignInLinkToEmail(auth, state.phoneNo, actionCodeSettings);
            localStorage.setItem('emailForSignIn', state.phoneNo);
            setState((prev) => ({ ...prev, step: state.step + 1, errorText: null, authMode: AuthMode.EmailLink }));            

        } catch (error) {
            console.log(error);
        } finally {
            setState((prev) => ({ ...prev, isLoading: false }));
        }

    }

    const verifyPhoneNo = async () => {
        try {
            const appVerifier = (window as any).recaptchaVerifier as RecaptchaVerifier;
            const result = await signInWithPhoneNumber(auth, state.phoneNo, appVerifier);

            setState((prev) => ({ ...prev, confirmResult: result }));
            setState((prev) => ({ ...prev, step: state.step + 1, errorText: null }));
        } catch (error) {
            if ((error as any).code) {
                const code = (error as any).code;
                if (code === 'auth/captcha-check-failed') {
                    setState((prev) => ({ ...prev, errorText: 'Captcha Check failed'}));
                    return;
                } else if (code === 'auth/invalid-phone-number') {
                    setState((prev) => ({ ...prev, errorText: t('phoneInvalid') }));
                    return;
                } else if (code === 'auth/missing-phone-number') {
                    setState((prev) => ({ ...prev, errorText: t('phoneMissing') }));
                    return;
                } else if (code === 'auth/quota-exceeded') {
                    setState((prev) => ({ ...prev, errorText: 'SMS quota has been exceeded'}));
                    return;
                }
            }

            const msg = _.get(error, 'response.data.message');
            console.log('error: ', msg)
            setState((prev) => ({
                ...prev, 
                errorText: msg || null
            }));
            
        } finally {
            setState((prev) => ({ ...prev, isLoading: false }));
        }
    }

    const redirectToDefaultPage = async () => {
        await auth.currentUser?.reload();

        if (auth.currentUser?.emailVerified) {
            const token = await auth.currentUser.getIdToken();
            const user = await dispatch(fetchUserInfo(token)).unwrap();
            localStorage.setItem(`9asset.userinfo`, JSON.stringify(user));

            window.parent?.postMessage({
                source: 'login',
                target: '9assetApp',
                type: 'logged-in',
                data: {
                    user: user,
                    token: token
                }
            }, '*');
            if (!isHeadlessMode) {
                
                if (params.has('redirect')) {
                    const url = decodeURIComponent(params.get('redirect') || '');
                    window.location.href = url;
                } else {
                    // props.history.push('/user');
                    console.log('redirectToDefaultPage: ', process.env.REACT_APP_HOME_URL)
                    window.location.href = process.env.REACT_APP_HOME_URL || ''; // 'https://my.9asset.com/buyer';
                }
            }
        } else {
            setState((prev) => ({ ...prev, emailVerified: false }));
        }
    }

    const getPasswordBox = () =>  {
        const showPassword = state.showPassword;

        if (state.authMode === AuthMode.PhoneNo) {
            return (<>
            <Grid item xs={12} sx={{ textAlign: 'center', margin: '30px auto 20px auto', justifyContent: 'center', display: 'flex' }}>
                <OtpInput
                    value={state.otp}
                    onChange={onOtpChanged}
                    numInputs={6}
                    separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                    isInputNum 
                    inputStyle={{ width: '1.5rem', height: '2.5rem', fontSize: '1.5rem', border: '1px solid', borderRadius: '4px' }}
                />
            </Grid>
            <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '5px' }}>
                <Typography variant="body2" color="error">{ state.passwordErrorText }</Typography>
            </Grid>
            <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '10px', paddingBottom: '0px' }}>
                <Typography variant="body2">{ t('otpNotReceived') }</Typography>
            </Grid>
            <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '10px', paddingTop: '0px' }}>
                <Button variant="text" id="recaptcha-button-resend" color="primary" size="small" onClick={onResendOtpRequest} >{ t('resend') }</Button>
            </Grid>
            </>);
        } else if (state.authMode === AuthMode.EmailWithPassword) {
            return (<>
            <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '15px' }}>
                <TextField
                    label={ t('password') }
                    variant="outlined"
                    margin="dense"
                    size="small"
                    type={ showPassword ? "text" : "password"}
                    error={state.passwordErrorText !== null}
                    helperText={state.passwordErrorText}
                    color={state.passwordErrorText !== null ? 'error' : 'primary' }
                    fullWidth
                    onChange={onPasswordChanged}
                    value={state.password} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'left', marginButtom: '8px', justifyContent: 'start' }}>
                <FormControlLabel control={<Checkbox onClick={onClickShowPassword} sx={{ padding: '2px', paddingLeft: '9px' }} />} label={ t('showPassword') } />
            </Grid>
            </>);
        } else {
            return (
            <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '8px' }}>
                Unknow Mode
            </Grid>
            );
        }
    }

    const renderEnterPassword = () => {

        const phoneNo = state.phoneNo;
        
        return (
            <Grid container sx={{ padding: '10px 40px' }} justifyContent="center" alignItems="center">
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Chip label={phoneNo}
                        clickable color="primary"
                        onDelete={onEdit}
                        deleteIcon={<EditIcon />}
                        variant="outlined"
                        sx={{ px: '6px', py: '10px' }}
                    />
                </Grid>
                { getPasswordBox() }
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ color: '#fff' }}
                        size="medium"
                        onClick={() => onLoginRequest() } 
                    >{t('login')}</Button>
                </Grid>
            </Grid>
        );
    }

    const renderWaitLinkClick = () => {
        return (
            <Grid container sx={{ padding: '10px 40px' }} justifyContent="center" alignItems="center">
                <Grid item xs={12} textAlign="center">
                    <Typography variant="h6" sx={{ fontSize: '16px' }}>Login Link was sent to your email.</Typography>
                    <Typography variant="body2" sx={{ margin: '10px 0px' }} >Please find email in your inbox to continue.</Typography>
                </Grid>
                <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '10px' }}>
                    <Button
                        variant="text" color="primary" size="small" 
                        sx={{ padding: '5px 15px' }} 
                        onClick={onResentEmailRequest}>{ t('resend') }</Button>
                </Grid>
                {/* <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '10px' }}>
                    <Button
                        variant="contained" color="primary" size="small" 
                        sx={{ padding: '5px 15px' }} 
                        onClick={onSigninWithLinkrequest }>{ t('login') }</Button>
                </Grid> */}
            </Grid>
        );
    }

    const renderDefault = () => {
        const phoneNo = state.phoneNo;
        return (
        <Grid container sx={{ padding: '10px 40px' }} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <TextField
                    label={t('signin.placeholder')} 
                    variant="outlined"
                    margin="normal"
                    size="small"
                    error={state.errorText !== null}
                    helperText={state.errorText}
                    color={state.errorText !== null ? 'error' : 'primary' }
                    fullWidth
                    onChange={onPhoneNoChanged}
                    value={phoneNo}>
                </TextField>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'left', marginButtom: '8px', justifyContent: 'start' }}>
                <FormControlLabel
                    control={
                        <Checkbox onChange={onRememberChanged} sx={{ padding: '2px', paddingLeft: '9px' }} checked={state.rememberMe} />
                    }
                    label={ t("rememberMe") }
                />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center', paddingTop: '15px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={onNextClicked}
                    sx={{ background: '#f4762a', color: '#fff', padding: '5px 25px' }}
                >
                    {t('next')}
                </Button>
            </Grid>
            <Grid item xs={12} sx={{ margin: '20px 0' }}>
                <DividerLine />
            </Grid>
            <Grid item xs={12} sx={{ }}>
                <Grid container spacing={2} style={{ flexGrow: 1, marginTop: '-5px' }}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <SocialButton social="google" onClick={onGoogleLoginRequest}>{t('google')}</SocialButton>
                    </Grid>
                    {/* <Grid item xs={6} md={6}>
                        <SocialButton social="facebook" onClick={onFacebookLoginRequest}>{t('facebook')}</SocialButton>
                    </Grid> */}
                </Grid>
            </Grid>
        </Grid>
        );
    }

    const onEmailChanged = (e: any) => {
        setState((prev) => ({ ...prev, email: e.target.value }));
    }

    const onNameChanged = (e: any) => {
        setState((prev) => ({ ...prev, 
            userInfo: {
                name: (e.target as any).value,
                lastname: state.userInfo.lastname
            }
        }));
    }

    const onLastnameChanged = (e: any) => {
        setState((prev) => ({ ...prev, userInfo: { lastname: (e.target as any).value, name: state.userInfo.name }}));
    }

    const onPhoneNo2Changed = (value: string, country: { countryCode: string, dialCode: string, name: string }) => {
        setState((prev) => ({
            ...prev, 
            phoneInfo: {
                value: value,
                dialCode: country.dialCode
            }
        }));
    }

    const onResentVerificationEmailRequest = async () => {
        try {
            setState((prev) => ({ ...prev, isLoading: true }));
            if (auth.currentUser) {
                await sendEmailVerification(auth.currentUser);
            }
            setState((prev) => ({ ...prev, isLoading: false }));
        } catch (error) {
            setState((prev) => ({ ...prev, isLoading: false }));
        }
    }

    const renderAcceptanceText = () => {
        const text = t('acceptance');
        const items = [];
        const inx1 = text.search('{p}');
        const inx2 = text.search('{/p}');
        items.push(text.substring(0, inx1));
        items.push(<Link href="/privacy-policy" target="_blank" key="privacy">{text.substring(inx1+3, inx2)}</Link>);
        items.push(text.substring(inx2+4));
        return <Box sx={{ fontSize: '0.9rem'}}>{items}</Box>;
    }

    const renderStep2 = () => {

        if (state.authMode === AuthMode.PhoneNo) {
            
            const name = state.userInfo.name;
            const lastname = state.userInfo.lastname;
        return (
        <Grid container sx={{ padding: '10px' }}>
            <Grid item xs={12} textAlign="center" sx={{ marginTop: '10px' }}>
                <Typography variant="h6" sx={{ fontSize: '16px' }}>{ t('emailIntroducction.line1') }</Typography>
            </Grid>
            <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '10px' }}>
                <TextField
                    type="email"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={onEmailChanged}
                    value={state.email}
                    margin="dense"
                    label={ `${t('email')}` }
                    error={!!state.emailError}
                    helperText={state.emailError}
                />
            </Grid>
            <Grid item sm={6} xs={12} sx={{ padding: '10px' }}>
                <TextField
                    type={ state.showPassword ? "text" : "password" }
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={onPasswordChanged}
                    value={state.password || ''}
                    margin="dense"
                    label={ `${t('password')} *`}
                    error={!!state.passwordErrorText}
                    helperText={state.passwordErrorText}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={onClickShowPassword}
                          onMouseDown={onMouseDownPassword}
                          edge="end"
                        >
                          { state.showPassword ? <VisibilityOff /> : <Visibility /> }
                        </IconButton>
                      </InputAdornment>
                    }}
                />
            </Grid>
            <Grid item sm={6} xs={6} sx={{ padding: '10px' }}>
                <TextField
                    label={ t('name') }
                    variant="outlined"
                    size="small"
                    value={name}
                    onChange={onNameChanged}
                    fullWidth
                    margin="dense"
                    error={!!state.nameError}
                    helperText={state.nameError}
                />
            </Grid>
            <Grid item sm={6} xs={6} sx={{ padding: '10px' }}>
                <TextField
                    label={ t('lastname') }
                    variant="outlined"
                    size="small"
                    value={lastname}
                    onChange={onLastnameChanged}
                    fullWidth
                    margin="dense"
                    error={!!state.lastnameError}
                    helperText={state.lastnameError}
                />
            </Grid>
            <Grid item sm={6} xs={12} sx={{ padding: '10px', paddingLeft: '20px' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            checked={state.isAcceptPrivacy}
                            onChange={(e) => { 
                                setState((prev) => ({ 
                                    ...prev, 
                                    isAcceptPrivacy: e.target.checked,
                                    acceptanceRequired: e.target.checked ? false : prev.acceptanceRequired
                                }))
                            }}
                        />
                        }
                        label={renderAcceptanceText()}
                    />
                { state.acceptanceRequired && <FormHelperText error>{ t('acceptanceRequired') }</FormHelperText> }
            </Grid>
        </Grid>
        );

        } else {
            return (
            <Grid container sx={{ padding: '10px' }}>
                <Grid item xs={12} textAlign="center" sx={{ marginTop: '10px' }}>
                    <Typography variant="h6" sx={{ fontSize: '16px' }}>{ t('emailIntroducction.line1') }</Typography>
                </Grid>
                <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '10px' }}>
                    <MuiPhoneNumber
                        label={'Phone No.'} 
                        defaultCountry={'th'}
                        variant="outlined"
                        margin="normal"
                        size="small"
                        fullWidth
                        onChange={onPhoneNo2Changed as React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> & ((e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void)}
                        value={state.phoneInfo.value}
                        error={!!state.phoneNoError}
                        helperText={state.phoneNoError}
                    />
                </Grid>
            </Grid>
            );
        }
    }

    const getMaskedPhoneNo = () => {
        const phoneNo = state.phoneInfo.value.slice(state.phoneInfo.dialCode.length+1);
        const regex = /^(\d{2})(\d+)(\d{3})$/;
        return `(+${state.phoneInfo.dialCode}) ${phoneNo.replace(regex, (all, g1, g2 ,g3) => `${g1}-xxx-${g3}` )}`;
    }

    const renderStep3 = () => {
        if (state.authMode === AuthMode.PhoneNo) {
            return (
                <Grid container>
                    <Grid item xs={12} textAlign="center" sx={{ marginTop: '10px' }}>
                        <Typography variant="h6" sx={{ fontSize: '16px' }}>{ t('verificationLinkSent') }</Typography>
                        <Typography variant="body2" sx={{ margin: '10px 0px' }} >{ t('verificationLinkGuide') }</Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '10px' }}>
                        <Button variant="contained" color="primary" size="small" sx={{ padding: '5px 15px' }} startIcon={<Replay />} onClick={onResentVerificationEmailRequest}>{ t('resend') }</Button>
                    </Grid>
                </Grid>
            );
        } else if (state.authMode === AuthMode.Google) {
            return (
                <Grid container>
                <Grid item xs={12} textAlign="center" sx={{ marginTop: '10px' }}>
                    <Typography variant="h6" sx={{ fontSize: '16px' }}>{ t('otpIntroduction.line1') }</Typography>
                    <Typography variant="body2">{ t('otpIntroduction.line2').replace('{0}', getMaskedPhoneNo()) }</Typography>
                </Grid>
                <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '10px' }}>
                    <OtpInput
                        value={state.otp}
                        onChange={onOtp2Changed}
                        numInputs={6}
                        separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                        isInputNum
                        // inputStyle={{ width: '1.5rem', height: '2.5rem', fontSize: '1.5rem', border: '1px solid', borderRadius: '4px' }}
                    />
                </Grid>
                <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '5px' }}>
                    <Typography variant="body2" color="error">{ state.otpError || '' }</Typography>
                </Grid>
                <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '10px', paddingBottom: '0px' }}>
                    <Typography variant="body2">{ t('otpNotReceived') }</Typography>
                </Grid>
                <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '10px', paddingTop: '0px' }}>
                    <Button variant="text" id="recaptcha-button-resend" color="primary" size="small" onClick={onResendOtp2Request} >{ t('resend') }</Button>
                </Grid>
            </Grid>
            );
        } else {
            return (<></>);
        }
    }

    const renderStep4 = () => {

        if (state.authMode === AuthMode.Google) {
            
            const name = state.userInfo.name;
            const lastname = state.userInfo.lastname;
        return (
        <Grid container>
            <Grid item xs={12} textAlign="center" sx={{ marginTop: '10px' }}>
                <Typography variant="h6" sx={{ fontSize: '16px' }}>{ t('emailIntroducction.line1') }</Typography>
            </Grid>
            <Grid item sm={6} xs={12} sx={{ padding: '10px' }}>
                <TextField
                    label={ t('name') }
                    variant="outlined"
                    size="small"
                    value={name}
                    onChange={onNameChanged}
                    fullWidth
                    margin="dense"
                    error={!!state.nameError}
                    helperText={state.nameError}
                />
            </Grid>
            <Grid item sm={6} xs={12} sx={{ padding: '10px' }}>
                <TextField
                    label={ t('lastname') }
                    variant="outlined"
                    size="small"
                    value={lastname}
                    onChange={onLastnameChanged}
                    fullWidth
                    margin="dense"
                    error={!!state.lastnameError}
                    helperText={state.lastnameError}
                />
            </Grid>
            <Grid item sm={6} xs={12} sx={{ padding: '10px' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            checked={state.isAcceptPrivacy}
                            onChange={(e) => { 
                                setState((prev) => ({ 
                                    ...prev, 
                                    isAcceptPrivacy: e.target.checked,
                                    acceptanceRequired: e.target.checked ? false : prev.acceptanceRequired
                                }))
                            }}
                        />
                        }
                        label={renderAcceptanceText()}
                    />
                { state.acceptanceRequired && <FormHelperText error>{ t('acceptanceRequired') }</FormHelperText> }
            </Grid>
        </Grid>
        );

        } else {
            return (<></>);
        }
    }

    const onNextStepRequested = async () => {
        if (state.step === 2) {
            if (state.authMode === AuthMode.PhoneNo) {
                // Send Email
                if (!auth.currentUser) {
                    return;
                }
                
                try {
                    setState((prev) => ({ ...prev, emailError: null, isLoading: true }));
                    if (!state.email || !state.userInfo.name || !state.userInfo.lastname || !state.isAcceptPrivacy) {
                        setState((prev) => ({ 
                            ...prev,
                            emailError: !state.email ? 'Required' : null,
                            nameError: !state.userInfo.name ? 'Required' : null,
                            lastnameError: !state.userInfo.lastname ? 'Required' : null,
                            acceptanceRequired: !state.isAcceptPrivacy,
                            isLoading: false
                        }));
                        return;
                    }

                    await updateEmail(auth.currentUser, state.email);

                    const data = {
                        phoneNo: auth.currentUser.phoneNumber || state.phoneNo,
                        email: auth.currentUser.email || state.email,
                        name: state.userInfo.name,
                        lastname: state.userInfo.lastname,
                    };
                    const token = await auth.currentUser.getIdToken();
                    const header = {
                        headers: { 'Authorization': `Bearer ${token}`}
                    };
                    await axios.put(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/`, data, header);
                    
                    const actionCodeSettings = {
                        url: window.location.href,
                        handleCodeInApp: true,
                    } as ActionCodeSettings;
                    await sendEmailVerification(auth.currentUser, actionCodeSettings);

                    setState((prev) => ({ ...prev, isLoading: false }));
                } catch (error: any) {
                    if (error.code) {
                        const code = (error as any).code;
                        if (code === AuthErrorCodes.EMAIL_EXISTS) {
                            setState((prev) => ({ ...prev, emailError: 'Email already exists', isLoading: false }));
                        } else if (code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) {
                            setState((prev) => ({ ...prev, step: 1, isLoading: false }));
                        } else if (code === AuthErrorCodes.INVALID_EMAIL) {
                            setState((prev) => ({ ...prev, email: 'Invalid email', isLoading: false }))
                        } else {
                            console.error(`updateEmai: ${code}`);
                        }

                        
                        return;
                    }
                    setState((prev) => ({ ...prev, isLoading: false }));
                }

            } else if (state.authMode === AuthMode.Google) {
                // state.unsubscribe && state.unsubscribe();
                try {
                    if (!state.userCredential) {
                        setState((prev) => ({ ...prev, step: 1 }));
                        return;
                    }
                    setState((prev) => ({ ...prev, isLoading: true }));
                    // const status = await getUserInfoStatus('phoneno', state.phoneInfo.value);
                    // if (status === 1) {
                    //     setState((prev) => ({ ...prev, phoneNoError: t('phoneExisted'), isLoading: false }));
                    //     return;
                    // }
                    
                    const result = await linkWithPhoneNumber(state.userCredential.user, state.phoneInfo.value, (window as any).recaptchaVerifier);
                    await axios.patch(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/${auth.currentUser?.uid}/phone-no-verified`, {});
                    // const result = await signInWithPhoneNumber(auth, state.phoneInfo.value, (window as any).recaptchaVerifier);
                    
                    setState((prev) => ({ ...prev, confirmResult: result, isLoading: false }));
                } catch (error) {
                    debugger;
                } finally {
                    setState((prev) => ({ ...prev, isLoading: false }));
                }
            }
            setState((prev) => ({ ...prev, step: 3 }));
        } else if (state.step === 3) {
            const confirmationResult = state.confirmResult;
            if (confirmationResult === null) {
                setState((prev) => ({ ...prev, step: 0 }));
                return;
            }

            try {

                setState((prev) => ({ ...prev, isLoading: true }));

                await confirmationResult.confirm(state.otp);

                // const userInfo = getAdditionalUserInfo(credential);
            
                if (!state.isNewUser) {
                    setState((prev) => ({ ...prev, isLoading: false }));
                    redirectToDefaultPage();
                    return;
                }

                if (auth.currentUser) {

                    const data = {
                        phoneNo: state.phoneNo,
                    };
                    const token = await auth.currentUser.getIdToken();
                    const header = {
                        headers: { 'Authorization': `Bearer ${token}`}
                    };
                    await axios.put(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/`, data, header);

                }
                setState((prev) => ({ ...prev, isNewUser: true, step: 4 }));
            } catch (error: any){
                if (error.code) {
                    const code = (error as any).code;

                    if (code === AuthErrorCodes.INVALID_CODE) { //'auth/invalid-verification-code') {
                        setState((prev) => ({ ...prev, otpError: t('invalidOtp'), isLoading: false }));
                        return;
                    } else if (code === AuthErrorCodes.NEED_CONFIRMATION) { // 'auth/account-exists-with-different-credential') {
                        const data = {
                            phoneNumber: state.phoneInfo.value
                        };
                        const token = await auth?.currentUser?.getIdToken();
                        const header = {
                            headers: { 'Authorization': `Bearer ${token}`}
                        };
                        await axios.patch(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/phoneno`, data, header);
                        await confirmationResult.confirm(state.otp);

                        if (state.isNewUser) {
                            setState((prev) => ({ ...prev, step: 4 }));
                            return;
                        }
                    } else {
                        setState((prev) => ({ ...prev, otpError: code }));
                        return;
                    }
                } else {
                    setState((prev) => ({ ...prev, otpError: error.message || error }));
                }

                redirectToDefaultPage();
            } finally {
                setState((prev) => ({ ...prev, isLoading: false }));
            }
        }
    }

    const onSkipRequested = async () => {
        if (state.authMode === AuthMode.Google) {

            setState((prev) => ({ ...prev, nameError: !state.userInfo.name ? 'Required' : null, lastnameError: !state.userInfo.lastname ? 'Required' : null }))
            if (!state.userInfo.name || !state.userInfo.lastname) {
                return;
            }

            if (!state.isAcceptPrivacy) {
                setState((prev) => ({ ...prev, acceptanceRequired: true }));
                return;
            }

            if (auth?.currentUser) {
                const data = {
                    phoneNo: state.phoneNo,
                    name: state.userInfo.name,
                    lastname: state.userInfo.lastname
                };
                const token = await auth.currentUser.getIdToken();
                const header = {
                    headers: { 'Authorization': `Bearer ${token}`}
                };
                await axios.put(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/`, data, header);
            }
        }
        redirectToDefaultPage();
    }

    const getDisclaimerText = () => {
        const link = <Link href="/privacy-policy" target="_blank">{t('privacyPolicy')}</Link>;
        const text = t('disclaimer').split('{0}');
        return (
        <Typography
            sx={{
                fontSize: '12px',
                textAlign: 'center'
            }}
        >{text[0]}{link}{text[1]}</Typography>);
    }

    const renderActions = () => {
        const lang = i18n.language;
        if (state.step >= 2) {
            return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                { ((state.step === 3 && state.authMode ===  AuthMode.PhoneNo) || (state.authMode ===  AuthMode.Google && state.step === 4)) &&
                <Box ml={1}>
                    <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={onSkipRequested}
                        disabled={state.step >= 5} >{ t('finish') }</Button>
                </Box>}
                { (state.step < 3 || (state.authMode ===  AuthMode.Google && state.step === 3)) && 
                <Box ml={1}>
                    <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={onNextStepRequested}
                        disabled={state.step >= 5} > { t('next') }</Button>
                </Box>
                }
            </Grid>)
        }

        return (<>
        <Grid container direction="column">
            <Grid container item direction="row" justifyContent="space-between" alignItems="center">                     
                <Box>
                    <Link component="button" variant="body2" underline="none" 
                        onClick={() => { navigate(`/register${location.search}`) }}>{t('signup.title')}</Link>
                    <span style={{margin: '0 8px 0 8px'}}>|</span>
                    <Link component="button" variant="body2" underline="none" 
                        onClick={() => { navigate(`/forgetpassword${location.search}`) }}>{t('forgetpassword')}</Link>
                </Box>
                <Box>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <Select
                            value={lang}
                            variant="outlined"
                            color="primary"
                            size="small"
                            input={<LanguageInput />}
                            onChange={onLangChanged}
                            sx={{ display: { xs: 'none', sm: 'block' }}}
                        >
                                <MenuItem value={'en'}>{t('language.en')}</MenuItem>
                                <MenuItem value={'th'}>{t('language.th')}</MenuItem>
                                <MenuItem value={'cn'}>{t('language.cn')}</MenuItem>
                        </Select>
                    </div>
                </Box>
            </Grid>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Grid item>
                { getDisclaimerText() }
            </Grid>
            <Grid item>
                <div style={{ fontSize: '10px',  color: '#d0d0d0', marginRight: '10px', marginTop: '5px' }} >
                                { `Version ${process.env.REACT_APP_BUILD_NUMBER || pk.version}` }
                </div>
            </Grid>
        </Grid>
        </>);
    }

    const renderLoginCard = () => {

        let stepContent = null;
        if (state.step === 0) {
            stepContent = renderDefault();
        } else if (state.step === 1) {
            if (state.authMode === AuthMode.PhoneNo) {
                stepContent = renderEnterPassword();
            } else if (state.authMode === AuthMode.EmailLink) {
                stepContent = renderWaitLinkClick();
            } else if (state.authMode === AuthMode.EmailWithPassword) {
                stepContent = renderEnterPassword();
            }
        } else if (state.step === 2) {
            stepContent = renderStep2();
        } else if (state.step === 3) {
            stepContent = renderStep3();
        } else if (state.step === 4) {
            stepContent = renderStep4();
        }

        return (
        <Card
            elevation={ isHeadlessMode ? 0 : 1 }
            sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: isHeadlessMode ? '' : '-40%',
                padding: isHeadlessMode ? '0px' : '10px 10px 20px 10px',
                width: '100%',
                height: isHeadlessMode ? '100%' : 'auto',
                
            }} >
            <LoginHeader title={t('signin.title')+''} subtitle={t('signin.subtitle')} />
            <CardContent style={{ padding: isHeadlessMode ? '0' : '8px' }} >
                { stepContent }    
            </CardContent>
            <CardActions
                disableSpacing
                sx={{
                    '&.MuiCardActions-root': {
                        marginTop: 'auto'
                    },
                    margin: isHeadlessMode ? '' : '10px',
                    marginBottom: '0',
                    
                    
                }}
            >
                { renderActions() }
            </CardActions>
        </Card>);
    }

    const renderEmailNotVerifed = () => {
        return (
        <Card
            elevation={ isHeadlessMode ? 0 : 1 }
            sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: isHeadlessMode ? '' : '-40%',
                padding: isHeadlessMode ? '0px' : '10px 10px 20px 10px',
                width: '100%',
                height: isHeadlessMode ? '100%' : 'auto',
                
            }} >
            <LoginHeader title={'9asset.com'} subtitle={'Email verification required.'} />
            <CardContent style={{ padding: isHeadlessMode ? '0' : '8px', textAlign: 'center' }} >
                <Typography variant="body2" sx={{ textAlign: 'center' }}>{ t('notVerifiedNotice') }</Typography>
                <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '15px' }}>{auth.currentUser?.email || ''}</Typography>
                <Button
                    sx={{ textTransform: 'capitalize', padding: '2px 10px', marginTop: '20px' }}
                    variant="contained"
                    onClick={async () => {
                        if (auth.currentUser) {
                            try {
                                setState((prev) => ({ ...prev, isLoading: true }));
                                await sendEmailVerification(auth.currentUser);
                            } finally {
                                setState((prev) => ({ ...prev, isLoading: false }));
                            }
                        }
                    }}
                >Get verification email</Button>
            </CardContent>
            <CardActions
                disableSpacing
                sx={{
                    '&.MuiCardActions-root': {
                        marginTop: 'auto'
                    },
                    margin: isHeadlessMode ? '' : '10px',
                    marginBottom: '0',
                    textAlign: 'center'
                    
                }}
            >
                <Grid container direction="row" justifyContent="center" alignItems="center">
                <Button
                    sx={{ textTransform: 'capitalize', padding: '2px 10px', marginTop: '20px' }}
                    variant="outlined"
                    onClick={async () => {

                        const token = await auth.currentUser?.getIdToken() || '';
                        const user = await dispatch(fetchUserInfo(token)).unwrap();
                        localStorage.setItem(`9asset.userinfo`, JSON.stringify(userInfo));
                        window.parent?.postMessage({
                            source: 'login',
                            target: '9assetApp',
                            type: 'logged-in',
                            data: {
                                user: user,
                                token: token
                            }
                        }, '*');
                        if (!isHeadlessMode) {
                            
                            if (params.has('redirect')) {
                                const url = decodeURIComponent(params.get('redirect') || '');
                                window.location.href = url;
                            } else {
                                // props.history.push('/user');
                                console.log('redirectToDefaultPage: ', process.env.REACT_APP_HOME_URL)
                                window.location.href = process.env.REACT_APP_HOME_URL || ''; // 'https://my.9asset.com/buyer';
                            }
                        }
                    }}
                >Close</Button>
                </Grid>
            </CardActions>
        </Card>
        );
    }

    return (<>
    <Grid container sx={{ maxWidth: 500, margin: '0 auto', padding: '4px', height: '100%' }}>
        <Grid item xs={12} container justifyContent="center" alignItems="center">
            {
                state.emailVerified !== false ? renderLoginCard() : renderEmailNotVerifed()
            }
        </Grid>
    </Grid>
    <div id="recaptcha-container"></div>
    <Backdrop className="loading-backdrop" open={state.isLoading}>
        <CircularProgress color="primary" />
    </Backdrop>
    </>);
}

export default Login;