import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const HrLineContainer = styled('div')(() => ({
    alignItems: 'center',
    WebkitBoxAlign: 'center',
    color: '#aeaeae',
    display: 'flex',
    margintTop: '20px'
}));


const HrLine = styled('div')(() => ({
    height: '.5px',
    width: '100%',
    background: '#dbdbdb',
    WebkitBoxFlex: 1,
    flex: 1,
    display: 'block',
}));

const HrLineText = styled('span')(() => ({
    padding: '0 5px',
    fontSize: '.8rem'
}));

export const DividerLine = () => {
    const { t } = useTranslation('common');
    return (
    <HrLineContainer>
        <HrLine />
        <HrLineText>{t('or')}</HrLineText>
        <HrLine />
    </HrLineContainer>);
}