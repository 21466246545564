import { configureStore } from '@reduxjs/toolkit';
import { mainReducer } from './mainSlice';
import { messegeReducer } from './messageSlice';

export const store = configureStore({
  reducer: {
    mainReducer,
    messegeReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;