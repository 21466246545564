import React, { useEffect, useState } from "react";
import { WithTranslation, useTranslation, withTranslation } from "react-i18next";
import {
    ActionCodeSettings,
    AuthErrorCodes,
    ConfirmationResult,
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    getAdditionalUserInfo,
    getAuth,
    GoogleAuthProvider,
    linkWithPhoneNumber,
    onIdTokenChanged,
    PhoneAuthProvider,
    RecaptchaVerifier,
    sendEmailVerification,
    signInWithPhoneNumber,
    signInWithPopup,
    unlink,
    Unsubscribe,
    updateEmail, 
    updatePassword} from 'firebase/auth';
import { Backdrop, Box, Button, Card, CardActions, CardContent, Checkbox, CircularProgress, Divider, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, Link, MenuItem, Select, Step, StepIconProps, StepLabel, Stepper, styled, TextField, Typography } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";

import pk from '../../package.json';
import { app, auth } from '../firebase';
import { LoginHeader } from "../components/loginHeader";
import { DividerLine } from "../components/DividerLine";
import { LanguageInput, SocialButton } from "../components";
import OtpInput from "../components/OtpInput";
import { CheckCircleOutline, Close, Home, Replay, Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { RouteComponentProps, withRouter } from "../with-router";
import { useLocation, useNavigate } from "react-router";

const RegisterStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    color: '#fff',
    zIndex: 1,
    width: 27,
    height: 27,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#aeaeae',
    fontSize: '12px',
    ...(ownerState.active && {
        backgroundImage:
          'linear-gradient( 136deg, rgb(244 101 15) 0%, rgb(244, 118, 42) 50%, rgb(255 196 160) 100%);',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
          'linear-gradient( 136deg, rgb(246 145 82) 0%, rgb(247 168 120) 50%, rgb(255 196 160) 100%);',
    }),
}))

const RegisterStepIcon = (props: StepIconProps) => {
    const { active, completed, className, icon } = props;

    return (
    <RegisterStepIconRoot ownerState={{ active, completed }} className={className}>
        { icon }
    </RegisterStepIconRoot>
    );
}

export interface RegisterProps extends RouteComponentProps, WithTranslation {

}

export interface RegisterState {
    step: number;
    loading: boolean;
    is3rdPartyAuth: boolean;
    existedUser: 'email' | 'mobile' | null;

    phoneNoError: string | null;
    otpError: string | null;
    emailError: string | null;
    passwordError: string | null;
    nameError: string | null;
    lastnameError: string | null;

    acceptanceRequired: boolean;
    accepted: boolean;

    countdown: number;

    userInfo: {
        name: string;
        lastname: string;
    }
    phoneNo: { value: string, dialCode: string };
    email: string;
    
    otp: string;
    password: string | null;
    confirmationResult: ConfirmationResult | null;
    countdownInterval: NodeJS.Timer | null;
    unsubscribe: Unsubscribe | null;

    showPassword: boolean;
    isHeadlessMode: boolean;
}

export const Register = (props: RegisterProps) => {
    
    const [state, setState] = useState<RegisterState>({
        step: 0,
        loading: false,
        is3rdPartyAuth: false,
        existedUser: null,
        phoneNoError: null,
        otpError: null,
        emailError: null,
        passwordError: null,
        nameError: null,
        lastnameError: null,
        countdown: 0,
        unsubscribe: null,

        acceptanceRequired: false,
        accepted: false,

        userInfo: { name: '', lastname: '' },
        phoneNo: { value: '', dialCode: '' },
        email: '',

        otp: '',
        password: null,
        confirmationResult: null,
        countdownInterval: null,

        showPassword: false,
        isHeadlessMode: false,
    });

    const { t, i18n } = useTranslation('common');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        componentDidMount();
    }, []);

    useEffect(() => {
        if (state.countdown === 10) {
            const int = setInterval(() => {
                setState((prev) => ({ ...prev, countdown: state.countdown - 1 }));
            }, 1000);
            setState((prev) => ({ ...prev, countdownInterval: int }));
        } else {
            if (state.countdown <= 0 && state.countdownInterval) {
                if (state.countdownInterval) {
                    clearInterval(state.countdownInterval);
                    setState((prev) => ({ ...prev, countdownInterval: null }));
                }
                if (state.isHeadlessMode) {
                    window.parent?.postMessage({
                        source: 'login',
                        target: '9assetApp',
                        type: 'logged-in',
                        data: {

                        }
                        }, '*');
                } else {
                    window.location.href = `${process.env.REACT_APP_HOME_URL || 'https://my.9asset.com'}`;
                }
            }
        }
    }, [state.countdown]);

    const componentDidMount = async () => {
        (window as any).recaptchaVerifier = new RecaptchaVerifier('grecaptcha-container',{ 'size': 'invisible' }, getAuth(app));

        const params = new URLSearchParams(window.location.search);
        const step = params.get('step') || null;
        if (step && step === 'userinfo') {
            setState((prev) => ({ ...prev, step: !state.is3rdPartyAuth ? 4 : 1 }));
        }
        
        setState((prev) => ({ ...prev, isHeadlessMode: params.get('isHeadlessMode') === 'true' }));
    }

    const getSteps = () => {
        if (state.is3rdPartyAuth) {
            return [
                { title: t('titleEnterMobile'), icon: {} },
                { title: t('mobileVerification'), icon: {} },
                { title: t('signupSteps.completed'), icon: {} }
            ];
        } else {
            return [
                { title: t('signupSteps.mobileOtp'), icon: {} },
                { title: t('signupSteps.email'), icon: {} },
                { title: t('signupSteps.emailVerification'), icon: {} },
                // { title: t('signupSteps.completed'), icon: {} }
            ];
        }
    }

    const getMaskedPhoneNo = () => {
        const phoneNo = state.phoneNo.value.slice(state.phoneNo.dialCode.length+1);
        const regex = /^(\d{2})(\d+)(\d{3})$/;
        return `(+${state.phoneNo.dialCode}) ${phoneNo.replace(regex, (all, g1, g2 ,g3) => `${g1}-xxx-${g3}` )}`;
    }

    const getUserInfoStatus = async (type: 'email' | 'phoneno', value: string) => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/status/${type}/${value}`);
           
            return response.data.status;
        } catch (error) {
           
        }
    }

    const onBackStepRequested = () => {
        backStep();
    }

    const onNextStepRequested = async () => {
        setState((prev) => ({
            ...prev, 
            loading: true, 
            otpError: null,
            emailError: null,
            phoneNoError: null,
        }));
        const currentStep = state.step;
        auth.currentUser?.reload();
       
        if(state.is3rdPartyAuth) {
            if (currentStep === 1) {
                setState((prev) => ({ ...prev, loading: true }));
                if (!auth.currentUser) {
                    return;
                }

                if ((state.userInfo.name || '').trim().length === 0) {
                    setState((prev) => ({ ...prev, nameError: 'Required', loading: false }));
                    return;
                }

                if ((state.userInfo.lastname || '').trim().length === 0) {
                    setState((prev) => ({ ...prev, lastnameError: 'Required', loading: false }));
                    return;
                }

                try {
                    const result = await linkWithPhoneNumber(auth.currentUser, state.phoneNo.value, (window as any).recaptchaVerifier);
                    await axios.patch(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/${auth.currentUser?.uid}/phone-no-verified`, {});
                
                    setState((prev) => ({ ...prev, confirmationResult: result, loading: false }));
                    nextStep();
                } catch (error: any) {
                    const code = error.code;
                    if (code) {
                        if (code === AuthErrorCodes.INVALID_PHONE_NUMBER) {
                            setState((prev) => ({ ...prev, phoneNoError: 'Invalid phone no.', loading: false }));
                            return;
                        } else if (code === AuthErrorCodes.MISSING_PHONE_NUMBER) {
                            setState((prev) => ({ ...prev, phoneNoError: 'Required', loading: false }));
                            return;
                        }  else if (code === AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE) {
                            setState((prev) => ({ ...prev, phoneNoError: 'Phone already exsits', loading: false }));
                            return;
                        }

                        setState((prev) => ({ ...prev, passwordError: code, loading: false }));
                        return;
                    }

                    setState((prev) => ({ ...prev, loading: false }));
                }

            } else if (currentStep === 2) {
                try {
                    const confirmationResult = state.confirmationResult;
                    if (confirmationResult === null) {
                        setState((prev) => ({ ...prev, step: 0 }));
                        return;
                    }

                    const credential = await confirmationResult.confirm(state.otp)
                    getAdditionalUserInfo(credential);
                    

                    if (auth.currentUser) {

                        const data = {
                            phoneNo: state.phoneNo,
                            name: state.userInfo.name,
                            lastname: state.userInfo.lastname
                        };
                        const token = await auth.currentUser.getIdToken();
                        const header = {
                            headers: { 'Authorization': `Bearer ${token}`}
                        };
                        await axios.put(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/`, data, header);

                    }
                    nextStep();
                    startCountdown();
                } catch (error: any) {
                    if (error.code === AuthErrorCodes.INVALID_EMAIL){ // 'auth/invalid-email' ) {
                        setState((prev) => ({ ...prev, emailError: t('emailInvalid') }));
                    } else if (error.code === 'auth/email-already-in-use') {
                        setState((prev) => ({ ...prev, emailError: t('emailIsInUsed') }));
                    } else if (error.code === AuthErrorCodes.CODE_EXPIRED) {
                        setState((prev) => ({ ...prev, otpError: t('otpExpired') }));
                    } else if (error.code === 'auth/invalid-verification-code') {
                        setState((prev) => ({ ...prev, otpError: t('invalidOtp') }));
                    } else if (error.code === AuthErrorCodes.NEED_CONFIRMATION) {
                        setState((prev) => ({ ...prev, otpError: 'Phone already exists' }));
                    } else {
                        setState((prev) => ({ ...prev, otpError: error.code }));
                    }
                }
            }
            setState((prev) => ({ ...prev, loading: false }));
        } else {
            if (currentStep === 1) {
                try {
                    const confirmationResult = state.confirmationResult;
                    if (confirmationResult === null) {
                        setState((prev) => ({ ...prev, step: 0 }));
                        return;
                    }

                    const credential = await confirmationResult.confirm(state.otp)
                    const userInfo = getAdditionalUserInfo(credential);
                    // if (!(userInfo?.isNewUser || false)) {
                    //     setState((prev) => ({ ...prev, loading: false, existedUser: 'email' }));
                    //     return;
                    // }

                    if (userInfo?.isNewUser && auth.currentUser) {

                        const data = {
                            phoneNo: state.phoneNo,
                            mobileVerified: true,
                        };
                        const token = await auth.currentUser.getIdToken();
                        const header = {
                            headers: { 'Authorization': `Bearer ${token}`}
                        };
                        await axios.put(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/`, data, header);

                    }
                    nextStep();
                    
                } catch (error: any) {
                    if (error.code === 'auth/code-expired') {
                        setState((prev) => ({ ...prev, otpError: t('otpExpired') }));
                    } else if (error.code === 'auth/invalid-verification-code') {
                        setState((prev) => ({ ...prev, otpError: t('invalidOtp') }));
                    } else {
                        setState((prev) => ({ ...prev, otpError: error.code }));
                    }
                }
            } else if (currentStep === 2) {
                try {
    
                    // TODO : Add Email format validation here
                    if (!auth.currentUser) {
                        return;
                    }

                    if ((state.email || '').trim().length === 0) {
                        setState((prev) => ({ ...prev, emailError: 'Required', loading: false }));
                        return;
                    }

                    if ((state.password || '').trim().length < 8) {
                        setState((prev) => ({ ...prev, passwordError: t('tooShortPassword'), loading: false }));
                        return;
                    }

                    if ((state.userInfo.name || '').trim().length === 0) {
                        setState((prev) => ({ ...prev, nameError: 'Required', loading: false }));
                        return;
                    }

                    if ((state.userInfo.lastname || '').trim().length === 0) {
                        setState((prev) => ({ ...prev, lastnameError: 'Required', loading: false }));
                        return;
                    }
                    
                    if (!auth.currentUser.email) {
                        await updateEmail(auth.currentUser, state.email);
                        await updatePassword(auth.currentUser, state.password || '');

                        const data = {
                            phoneNo: auth.currentUser.phoneNumber || state.phoneNo,
                            email: auth.currentUser.email || state.email,
                            name: state.userInfo.name,
                            lastname: state.userInfo.lastname,
                        };
                        const token = await auth.currentUser.getIdToken();
                        const header = {
                            headers: { 'Authorization': `Bearer ${token}`}
                        };
                        await axios.put(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/`, data, header);
                    } else if (auth.currentUser.email && auth.currentUser.email === state.email) {
                        await updatePassword(auth.currentUser, state.password || '');

                        const data = {
                            phoneNo: auth.currentUser.phoneNumber || state.phoneNo,
                            email: auth.currentUser.email || state.email,
                            name: state.userInfo.name,
                            lastname: state.userInfo.lastname,
                        };
                        const token = await auth.currentUser.getIdToken();
                        const header = {
                            headers: { 'Authorization': `Bearer ${token}`}
                        };
                        await axios.put(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/`, data, header);
                    } else{
                        const phoneNo = auth.currentUser.phoneNumber;
                        await unlink(auth.currentUser, PhoneAuthProvider.PROVIDER_ID);
                        
                        if (state.email && state.password) {
                            const credential = await createUserWithEmailAndPassword(auth, state.email, state.password);
                            
                            const data = {
                                phoneNo: phoneNo,
                                email: credential.user.email,
                                name: state.userInfo.name,
                                lastname: state.userInfo.lastname,
                            };
                            await auth.currentUser.reload();
                            const token = await auth.currentUser.getIdToken();
                            const header = {
                                headers: { 'Authorization': `Bearer ${token}`}
                            };
                            await axios.post(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/`, data, header);
                            
                            await sendEmailVerification(auth.currentUser);
                            console.log(auth.currentUser)
                        }
                    }
                    // await updateEmail(auth.currentUser, state.email);
                    // await updatePassword(auth.currentUser, state.password || '');

                    // const data = {
                    //     phoneNo: auth.currentUser.phoneNumber || state.phoneNo,
                    //     email: auth.currentUser.email || state.email,
                    //     name: state.userInfo.name,
                    //     lastname: state.userInfo.lastname,
                    // };
                    // const token = await auth.currentUser.getIdToken();
                    // const header = {
                    //     headers: { 'Authorization': `Bearer ${token}`}
                    // };
                    // await axios.put(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/v1/users/`, data, header);
                    
                    // const actionCodeSettings = {
                    //     url: window.location.href,
                    //     handleCodeInApp: true,
                    // } as ActionCodeSettings;
                    
                    // const unsubscribe = onIdTokenChanged(auth, (user) => {
                    //     const unsubscribeTimeInterval = setTimeout(() => {
                    //         auth.currentUser?.reload();
                    //     }, 1500);

                    //     if (user && user.emailVerified) {
                    //         clearInterval(unsubscribeTimeInterval);
                    //         state.unsubscribe?.();
                    //     }
                    // })
                    // setState((prev) => ({ ...prev, unsubscribe: unsubscribe }));
                    // await sendEmailVerification(auth.currentUser, actionCodeSettings);

            
                    nextStep();
                } catch (error: any) {
                    console.log(error)
                    if (error.code === 'auth/invalid-email' ) {
                        setState((prev) => ({ ...prev, emailError: t('emailInvalid') }));
                    } else if (error.code === 'auth/email-already-in-use') {
                        setState((prev) => ({ ...prev, emailError: t('emailIsInUsed') }));
                    } else if (error.code === 'auth/missing-email') {
                        setState((prev) => ({ ...prev, emailError: 'Required' }));
                    } else {
                        setState((prev) => ({ ...prev, emailError: error.code || error }));
                    } 

                    setState((prev) => ({ ...prev, loading: false }));
                }
            } else if (currentStep === 3) {
                if (auth.currentUser) {
                    await auth.currentUser.reload();
                    const token = await auth.currentUser.getIdToken();
                    const userInfo = (await axios.get(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users?from=authprovider`, { headers: { 'Authorization': `Bearer ${token}`} })).data;
                    // nextStep();
                    // startCountdown();
                    if (state.isHeadlessMode) {
                        window.parent?.postMessage({
                            source: 'login',
                            target: '9assetApp',
                            type: 'logged-in',
                            data: {
                                user: userInfo,
                                token: token
                            }
                        }, '*');
                    } else {
                        window.location.href = `${process.env.REACT_APP_HOME_URL || 'https://my.9asset.com'}`;
                    }
                }
            } 
        }
        setState((prev) => ({ ...prev, loading: false }));
    }

    const startCountdown = () => {
        setState((prev) => ({...prev, countdown: 10} ));
    }

    const onFacebookLoginRequest = async () => {
        setState((prev) => ({ ...prev, loading: true, is3rdPartyAuth: true }));
        const provider = new FacebookAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            if(result.user?.displayName) {
                const [name, lastname] = result.user.displayName.split(' ', 2);
                const userInfo = { name: name || '', lastname: lastname || '' };
                setState((prev) => ({ ...prev, userInfo: userInfo }));
            }
            setState((prev) => ({ ...prev, is3rdPartyAuth: true }));
            setState((prev) => ({ ...prev, step: 1 }));
        } catch (error) {

        } finally {
            setState((prev) => ({ ...prev, loading: false }));
        }
    }

    const onGoogleLoginRequest = async () => {
        if (!state.accepted) {
            setState((prev) => ({ ...prev, acceptanceRequired: true }));
            return;
        }

        setState((prev) => ({ ...prev, loading: true, is3rdPartyAuth: true }));
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const userInfo = getAdditionalUserInfo(result);
            if (!userInfo?.isNewUser) {
                setState((prev) => ({ ...prev, existedUser: 'email' }));
                return;
            }

            if(result.user?.displayName) {
                const [name, lastname] = result.user.displayName.split(' ', 2);
                const userInfo = { name: name || '', lastname: lastname || '' };
                setState((prev) => ({ ...prev, userInfo: userInfo }));
            }

            if (auth && auth.currentUser) {
                const data = {
                    phoneNo: auth.currentUser.phoneNumber || state.phoneNo,
                    email: auth.currentUser.email ||state.email
                    
                };
                const token = await auth.currentUser.getIdToken();
                const header = {
                    headers: { 'Authorization': `Bearer ${token}`}
                };
                await axios.put(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users/`, data, header);
            }


            setState((prev) => ({ ...prev, is3rdPartyAuth: true }));
            setState((prev) => ({ ...prev, step: 1 }));
        } catch (error) {

        } finally {
            setState((prev) => ({ ...prev, loading: false }));
        }
    }

    const onLangChanged = async (event: any) => {
        await i18n.changeLanguage(event.target.value);
        localStorage.setItem("9asset_language", JSON.stringify({ language: i18n.language }));
    }

    const onOtpChanged = (otp: string) => {
        setState((prev) => ({ ...prev, otp: otp }));
    }

    const onResendOtpRequest = async () => {
        try {
            setState((prev) => ({ ...prev, loading: true, otp: '' }));

            const result = await signInWithPhoneNumber(auth, state.phoneNo.value, (window as any).recaptchaVerifier);
            setState((prev) => ({ ...prev, confirmationResult: result, loading: false }));
        } catch (error) {
            setState((prev) => ({ ...prev, loading: false }));
        }
    }

    const onPhoneNoChanged = (value: string, country: { countryCode: string, dialCode: string, name: string }) => {
        setState((prev) => ({ ...prev,
            phoneNo: {
                value: value,
                dialCode: country.dialCode
            },
            phoneNoError: null,
        }));
    }

    const onEmailChanged = (e: any) => {
        setState((prev) => ({ ...prev, email: e.target.value }));
    }

    const onPasswordChanged = (e: any) => {
        setState((prev) => ({ ...prev, password: e.target.value }));
    }

    const onRegisterClicked = async () => {
        if (!state.accepted) {
            setState((prev) => ({ ...prev, acceptanceRequired: true }));
            return;
        }

        try {
            if (!state.phoneNoError) {
                setState((prev) => ({ ...prev, is3rdPartyAuth: false }));
                setState((prev) => ({ ...prev, loading: true }));

                // const status = await getUserInfoStatus('phoneno', state.phoneNo.value);
                // if (status === 1) {
                //     setState((prev) => ({ ...prev, phoneNoError: t('phoneExisted'), loading: false }));
                //     return;
                // }
                const result = await signInWithPhoneNumber(auth, state.phoneNo.value, (window as any).recaptchaVerifier);
            
                setState((prev) => ({ ...prev, confirmationResult: result, loading: false }));

                nextStep();
            } else {
                navigate(`/${location.search}&authmode=phone&phone=${encodeURIComponent(`${state.phoneNo.value}`)}`);
            }
        } catch (error: any) {
            if (error.code === 'auth/invalid-phone-number') {
                setState((prev) => ({ ...prev, loading: false, phoneNoError: t('phoneInvalid') }));
            } else if (error.code === 'auth/missing-phone-number') {
                setState((prev) => ({ ...prev, loading: false, phoneNoError: t('phoneMissing') }));
            } else {
                setState((prev) => ({ ...prev, loading: false, phoneNoError: error.code }));
            }
        }
    }

    const onNameChanged = (e: any) => {
        setState((prev) => ({ ...prev,
            userInfo: {
                name: (e.target as any).value,
                lastname: state.userInfo.lastname
            }
        }));
    }

    const onLastnameChanged = (e: any) => {
        setState((prev) => ({ ...prev, userInfo: { lastname: (e.target as any).value, name: state.userInfo.name }}));
    }

    const onResentEmailRequest = async () => {
        try {
            setState((prev) => ({ ...prev, loading: true }));
            if (auth.currentUser) {
                await sendEmailVerification(auth.currentUser);
            }
            setState((prev) => ({ ...prev, loading: false }));
        } catch (error) {
            setState((prev) => ({ ...prev, loading: false }));
        }
    }

    const onCloseRequest = async (destination: string | null) => {
        if (state.isHeadlessMode) {
            const token = await auth.currentUser?.getIdToken();
            const userInfo = (await axios.get(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users?from=authprovider`, { headers: { 'Authorization': `Bearer ${token}`} })).data;
            window.parent?.postMessage({
                source: 'login',
                target: '9assetApp',
                type: 'logged-in',
                data: {
                    user: userInfo,
                    token: token
                }
              }, '*');
            return;
        }

        if (destination !== null) {
            navigate(`${destination}`);
        } else {
            window.location.href = `${process.env.REACT_APP_HOME_URL || 'https://my.9asset.com'}`;
        }
    }

    const nextStep = () => {
        const step = state.step + 1;
        setState((prev) => ({ ...prev, step: step }));

        if (!state.is3rdPartyAuth && step === 3 && auth.currentUser?.emailVerified) {
            // nextStep();
            // const timeInterval = setInterval(async () => {
            //     if (auth.currentUser) {
            //         await auth.currentUser.reload();
            //         if (auth.currentUser.emailVerified) {
            //             clearInterval(state.checkUserTimeInterval);

            //             const data = {
            //                 phoneNo: state.phoneNo,
            //                 email: auth.currentUser.email || state.email,
            //             };
            //             const token = await auth.currentUser.getIdToken();
            //             const header = {
            //                 headers: { 'Authorization': `Bearer ${token}`}
            //             };
            //             await axios.put(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/v1/users/`, data, header);
                          
            //             setState((prev) => ({ ...prev, checkUserTimeInterval: null }));
            //             nextStep();
            //         }
            //     } else {
            //         clearInterval(state.checkUserTimeInterval);
            //         setState((prev) => ({ ...prev, checkUserTimeInterval: null }));
            //     }
            // }, 1000);

            // setState((prev) => ({ ...prev, checkUserTimeInterval: timeInterval }));
        }
    }

    const backStep = () => {
        setState((prev) => ({ ...prev, step: state.step - 1, otp: '', loading: false })); 
    }

    const renderAcceptanceText = () => {
        const text = t('acceptance');
        const items = [];
        const inx1 = text.search('{p}');
        const inx2 = text.search('{/p}');
        items.push(text.substring(0, inx1));
        items.push(<Link href="/privacy-policy" target="_blank" key="privacy">{text.substring(inx1+3, inx2)}</Link>);
        items.push(text.substring(inx2+4));
        return <Box sx={{ fontSize: '0.9rem'}}>{items}</Box>;
    }

    const renderDefault = () => {
        const lang = i18n.language;
        
        return (<>
        <CardContent style={{ padding: '16px' }}>
            <Grid container sx={{ padding: '10px 20px', pt: 0 }}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <MuiPhoneNumber
                        label={t('phoneNo')} 
                        defaultCountry={'th'}
                        variant="outlined"
                        margin="normal"
                        size="small"
                        fullWidth
                        onChange={onPhoneNoChanged as React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> & ((e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void)}
                        value={state.phoneNo.value}
                        error={!!state.phoneNoError}
                        helperText={state.phoneNoError}
                    />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            size="small"
                            checked={state.accepted}
                            onChange={(e) => { 
                                setState((prev) => ({ 
                                    ...prev, 
                                    accepted: e.target.checked,
                                    acceptanceRequired: prev.acceptanceRequired && e.target.checked ? false : prev.acceptanceRequired
                                }))
                            }}
                        />
                        }
                        label={renderAcceptanceText()}
                    />
                    { state.acceptanceRequired && <FormHelperText error>{ t('acceptanceRequired') }</FormHelperText> }
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center', paddingTop: '15px' }}>
                    <Button
                        variant="contained"
                        sx={{ background: '#f4762a', color: '#fff', padding: '5px 25px' }}
                        size="small"
                        onClick={onRegisterClicked}
                        >
                    { !!state.phoneNoError ? t('login') : t('next') }
                    </Button>
                </Grid>
                <Grid item xs={12} sx={{ margin: '20px 0' }}>
                    <DividerLine />
                </Grid>
                <Grid item xs={12} sx={{ }}>
                    <Grid container spacing={2} style={{ flexGrow: 1, marginTop: '-5px' }}>
                        <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <SocialButton social="google" onClick={onGoogleLoginRequest} >{t('google')}</SocialButton>
                        </Grid>
                        {/* <Grid item xs={6} md={6}>
                            <SocialButton social="facebook" onClick={onFacebookLoginRequest}>{t('facebook')}</SocialButton>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </CardContent>
        <CardActions
            sx={{ 
                '&.MuiCardActions-root': {
                    marginTop: 'auto'
                },
                margin: state.isHeadlessMode ? '' : '10px',
                marginBottom: '0px',
            }}
        >

            <Grid container direction="column">
                <Grid container item direction="row" justifyContent="space-between" alignItems="center">
                    <Box>
                        <Link component="button" variant="body2" underline="none" onClick={() => { navigate(`/${location.search}`) }}>{t('signin.label')}</Link>
                        <span style={{margin: '0 8px 0 8px'}}>|</span>
                        <Link component="button" variant="body2" underline="none" onClick={() => { navigate(`/forgetpassword${location.search}`) }}>{t('forgetpassword')}</Link>
                    </Box>
                    <Box>
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                            <Select
                                value={lang}
                                variant="outlined"
                                color="primary"
                                size="small"
                                input={<LanguageInput />}
                                onChange={onLangChanged}
                                sx={{ display: { xs: 'none', sm: 'block' }}}
                            >
                                    <MenuItem value={'en'}>{t('language.en')}</MenuItem>
                                    <MenuItem value={'th'}>{t('language.th')}</MenuItem>
                                    <MenuItem value={'cn'}>{t('language.cn')}</MenuItem>
                            </Select>
                        </div>
                    </Box>
                </Grid>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Grid item>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            textAlign: 'center'
                        }}
                        >I agree to 9asset’s <Link href="/privacy-policy" target="_blank">Privacy Policy</Link> including the collection, use and disclosure of my personal information.</Typography>
                </Grid>
                <Grid item>
                <div style={{ fontSize: '10px',  color: '#d0d0d0', marginRight: '10px', marginTop: '5px' }} >
                                { `Version ${process.env.REACT_APP_BUILD_NUMBER || pk.version}` }
                            </div>
                </Grid>
            </Grid>
        </CardActions>
        </>);
    }

    const renderStepComponent = () => {
        const page = state.step - 1;
        const steps = getSteps();
        return (
        <Stepper activeStep={page} alternativeLabel sx={{ marginTop: '20px' }}>
            {
                steps.map((item, index) => (
                    <Step key={item.title}>
                        <StepLabel
                            StepIconComponent={RegisterStepIcon}
                        >
                            {item.title}
                        </StepLabel>
                    </Step>
                ))
            }
        </Stepper>
        );
    }

    const renderVerifyMobile = () => {
        return (
        <Grid container sx={{  }}>
            <Grid item xs={12} textAlign="center" sx={{ marginTop: '10px' }}>
                <Typography variant="h6" sx={{ fontSize: '16px' }}>{ t('otpIntroduction.line1') }</Typography>
                <Typography variant="body2">{ t('otpIntroduction.line2').replace('{0}', getMaskedPhoneNo()) }</Typography>
            </Grid>
            <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '10px' }}>
                <OtpInput
                    value={state.otp}
                    onChange={onOtpChanged}
                    numInputs={6}
                    separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                    isInputNum
                />
            </Grid>
            <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '5px' }}>
                <Typography variant="body2" color="error">{ state.otpError || '' }</Typography>
            </Grid>
            <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '10px', paddingBottom: '0px' }}>
                <Typography variant="body2">{ t('otpNotReceived') }</Typography>
            </Grid>
            <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '10px', paddingTop: '0px' }}>
                <Button variant="text" id="recaptcha-button-resend" color="primary" size="small" onClick={onResendOtpRequest} >{ t('resend') }</Button>
            </Grid>
        </Grid>
        );
    }

    const onClickShowPassword = () => {
        setState((prev) => ({ ...prev, showPassword: !state.showPassword }));
    }

    const onMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }

    const renderEnterEmail = () => {
        const name = state.userInfo.name;
        const lastname = state.userInfo.lastname;
        return (
        <Grid container>
            <Grid item xs={12} textAlign="center" sx={{ marginTop: '0px' }}>
                <Typography variant="h6" sx={{ fontSize: '16px' }}>{ t('emailIntroducction.line1') }</Typography>
            </Grid>
            <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '5px 10px 5px 10px' }}>
                <TextField
                    type="email"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={onEmailChanged}
                    value={state.email}
                    margin="dense"
                    label={ `${t('email')} *`}
                    error={!!state.emailError}
                    helperText={state.emailError}
                />
            </Grid>
            <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '5px 10px 5px 10px'  }}>
                <TextField
                    type={ state.showPassword ? "text" : "password" }
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={onPasswordChanged}
                    value={state.password || ''}
                    margin="dense"
                    label={ `${t('password')} *`}
                    error={!!state.passwordError}
                    helperText={state.passwordError}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={onClickShowPassword}
                          onMouseDown={onMouseDownPassword}
                          edge="end"
                        >
                          { state.showPassword ? <VisibilityOff /> : <Visibility /> }
                        </IconButton>
                      </InputAdornment>
                    }}
                />
            </Grid>
            <Grid item sm={6} xs={6} sx={{ padding: '5px 10px 5px 10px' }}>
                <TextField
                    label={ `${t('name')} *`}
                    variant="outlined"
                    size="small"
                    value={name}
                    onChange={onNameChanged}
                    fullWidth
                    margin="dense"
                    error={!!state.nameError}
                    helperText={state.nameError}
                />
            </Grid>
            <Grid item sm={6} xs={6} sx={{ padding: '5px 10px 5px 10px' }}>
                <TextField
                    label={ `${t('lastname')} *`}
                    variant="outlined"
                    size="small"
                    value={lastname}
                    onChange={onLastnameChanged}
                    fullWidth
                    margin="dense"
                    error={!!state.lastnameError}
                    helperText={state.lastnameError}
                />
            </Grid>
        </Grid>
        );
    }

    const renderEnterPhoneNo = () => {
        const name = state.userInfo.name;
        const lastname = state.userInfo.lastname;
        return (
        <Grid container spacing={2} justifyContent="center" sx={{ padding: '10px 20px' }}>
            <Grid item xs={12} textAlign="center" sx={{ marginTop: '5px' }}>
                { t('enterPhoneNo') }
            </Grid>
            <Grid item xs={12} sx={{ margin: '5px 0px', paddingTop: '4px !important' }}>
                <MuiPhoneNumber
                    label={ `${t('phoneNo')}`}
                    defaultCountry={'th'}
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    onChange={onPhoneNoChanged as React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> & ((e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void)}
                    value={state.phoneNo.value}
                    error={!!state.phoneNoError}
                    helperText={state.phoneNoError}
                />
            </Grid>
            <Grid item sm={6} xs={12} sx={{ paddingTop: '4px !important' }}>
                <TextField
                    label={ `${t('name')}`}
                    variant="outlined"
                    size="small"
                    value={name}
                    onChange={onNameChanged}
                    fullWidth
                    margin="dense"
                    error={!!state.nameError}
                    helperText={state.nameError}
                />
            </Grid>
            <Grid item sm={6} xs={12} sx={{ paddingTop: '4px !important' }}>
                <TextField
                    label={ `${t('lastname')}`}
                    variant="outlined"
                    size="small"
                    value={lastname}
                    onChange={onLastnameChanged}
                    fullWidth
                    margin="dense"
                    error={!!state.lastnameError}
                    helperText={state.lastnameError}
                />
            </Grid>
        </Grid>
        );
    }

    const renderGetUserInfo = () => {
        const name = state.userInfo.name;
        const lastname = state.userInfo.lastname;
        return (
            <form autoComplete="off">
                <Grid container spacing={2} className="user-info-container" style={{ padding: '0 15px', marginTop: '5px' }}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            label={ `${t('name')}`}
                            variant="outlined"
                            size="small"
                            value={name}
                            onChange={onNameChanged}
                            fullWidth
                            margin="dense" />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            label={ `${t('lastname')}`}
                            variant="outlined"
                            size="small"
                            value={lastname}
                            onChange={onLastnameChanged}
                            fullWidth
                            margin="dense" />
                    </Grid>
                </Grid>
            </form>
        );
    }

    const renderCompleted = () => {
        return (
            <div>
                <Box justifyContent="center" justifyItems="center" sx={{ display: 'flex' }}>
                    <CheckCircleOutline sx={{ margin: '20px auto 15px', display: 'block', width: '70px', height: '70px', color: 'forestgreen' }}></CheckCircleOutline>
                </Box>
                <Box width={1} display="block" justifyContent="center" justifyItems="center" sx={{ height: '80px' }}>
                    <span style={{ margin: '10 auto', display: 'block', textAlign: 'center' }}>{ t('thankmessage') }</span>
                </Box>
                <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: '25px' }} spacing={2}>
                    { !state.isHeadlessMode ? (<>
                    <Grid item>    
                        <Button style={{ color: '#ffffff' }} startIcon={<Home />} variant="contained" color="primary" onClick={() => onCloseRequest(null)}>{ t('home')}</Button>
                    </Grid>
                    <Grid item>
                        <Button startIcon={<Home />} variant="outlined" color="primary" onClick={() => onCloseRequest('')}>{ t('login') }</Button>
                    </Grid>
                    </>) : (<>
                    <Grid item>
                    <Button startIcon={<Close />} variant="outlined" color="primary" onClick={() => onCloseRequest('')}>{ 'close' }</Button>
                    </Grid>
                    </>) }
                </Grid>
            </div>   
        );
    }

    const renderVerifyEmail = () => {
      
        return (
            <Grid container>
                <Grid item xs={12} textAlign="center" sx={{ marginTop: '10px' }}>
                    <Typography variant="h6" sx={{ fontSize: '16px' }}>{ t('verificationLinkSent') }</Typography>
                    <Typography variant="body2" sx={{ margin: '0px 0px 10px 0px' }} >({ state.email })</Typography>
                    <Typography variant="body2" sx={{ margin: '10px 0px' }} >{ t('verificationLinkGuide') }</Typography>
                </Grid>
                <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" sx={{ padding: '10px' }}>
                    <Button variant="contained" color="primary" size="small" sx={{ padding: '5px 15px' }} startIcon={<Replay />} onClick={onResentEmailRequest}>{ t('resend') }</Button>
                </Grid>
            </Grid>
        );
    }

    const renderStepContents = () => {
        const step = state.step - 1 ;
        const pages = state.is3rdPartyAuth
                ? [
                    renderEnterPhoneNo(),
                    renderVerifyMobile(),
                    renderCompleted()
                ]
                : [
                    renderVerifyMobile(),
                    renderEnterEmail(),
                    renderVerifyEmail(),
                    // renderGetUserInfo(),
                    renderCompleted()
                ];
        return pages[step];
    }

    const renderControlButtonPanel = () => {
        const page = state.step - 1;
        const steps = getSteps();
        const buttons = [];
        const back = (<Box key={"back-container"}>
            <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={onBackStepRequested}
                disabled={page < 0 } >{ t('back') }</Button>
        </Box>);
        const next = (<Box ml={1} key={"next-container"}>
            <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={onNextStepRequested}
                disabled={page >= 5} >{ t('next') }</Button>
        </Box>);
        const submit = (<Box ml={1} key={"next-container"}>
            <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={onNextStepRequested}
                disabled={page >= 5} >{ 'submit' }</Button>
        </Box>);
        const finish = (<Box ml={1} key={"next-container"}>
            <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={onNextStepRequested}
                disabled={page >= 5} >{ t('finish') }</Button>
        </Box>);
        if (!state.is3rdPartyAuth && state.step === 1) {
            buttons.push(back);
            buttons.push(next);
        } else if (!state.is3rdPartyAuth && state.step === 2) {
            buttons.push(submit);
        } else if (state.step === 3) {
            buttons.push(back);
            buttons.push(finish);
        }

        if (buttons.length > 0) {
            return (
            <Grid container direction="row" justifyContent="center" alignItems="center" className="end-panel">
                { buttons }
            </Grid>);
        } else {
            return (<Grid container direction="row" justifyContent="flex-end" alignItems="center" className="end-panel"></Grid>);
        }
    }

    const renderSteps = () => {
        return (<>
            { renderStepComponent() }
            <CardContent sx={{ minHeight: '231px' }}>
                { renderStepContents() }
            </CardContent>
            <CardActions
                sx={{ 
                    '&.MuiCardActions-root': {
                        marginTop: 'auto'
                    },
                    margin: state.isHeadlessMode ? '' : '10px',
                    marginBottom: '5px',
                    marginRight: '5px'
                }}
            >
                { renderControlButtonPanel() }
            </CardActions>
        </>);
    }

    const renderUserExisted = () => {
        return (<>
        <CardContent sx={{ height: '335px' }}>
        <Grid container>
            <Grid item xs={12} textAlign="center" sx={{ marginTop: '10px' }}>
                <Typography variant="h6" sx={{ fontSize: '16px' }}>{ state.existedUser === 'mobile' ? t('phoneExisted2') : t('emailExisted') }</Typography>
                {/* <Typography variant="body2" sx={{ margin: '10px 0px' }} >{ t('phoneExistedGuide').replace('{0}', getMaskedPhoneNo()) }</Typography> */}
            </Grid>
        </Grid>
        </CardContent>
        <CardActions
            sx={{ 
                '&.MuiCardActions-root': {
                    marginTop: 'auto'
                },
                margin: state.isHeadlessMode ? '' : '10px',
                marginBottom: '0px',
            }}
        >
            <Grid container direction="row" justifyContent="space-between" alignItems="center" className="end-panel">
                <Box>
                <Link component="button" variant="body2" underline="none" onClick={() => { navigate(`/forgetpassword${location.search}`) }}>{t('forgetpassword')}</Link>
                </Box>
                <Box ml={1}>
                    <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        // href="/login/register"
                        onClick={() => { setState((prev) => ({ ...prev, step: 0, existedUser: null })) }}
                        sx={{ mr: 2 }}
                    >{ state.existedUser === 'mobile' ? t('changePhoneNo') : t('changeEmail') }</Button>
                    <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        href="/login"
                        sx={{ mr: 0 }}
                    >{ t('login') }</Button>
                </Box>
            </Grid>
        </CardActions>
        </>);
    }

    const renderCardContent = () => {
        const steps = getSteps();
        if (state.existedUser) {
            return renderUserExisted();
        } else
        if (state.step === 0) {
            return renderDefault();
        } else if (state.step > 0 && state.step <= steps.length) {
            return renderSteps();
        }
        return (
        <CardContent>
            <span>Error: Unknown Step</span>
        </CardContent>);
    }

    return (<>
        <div id="grecaptcha-container"></div>
        <Grid container sx={{ maxWidth: 500, margin: '0 auto', padding: '4px', height: '100%' }}>
            <Grid item xs={12} container justifyContent="center" alignItems="center">
                <Card
                    elevation={ state.isHeadlessMode ? 0 : 1 } 
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: state.isHeadlessMode ? '' : '-40%',
                        padding: state.isHeadlessMode ? '0px' : '10px 10px 10px 10px',
                        width: '100%',
                        height: state.isHeadlessMode ? '100%' : 'auto',
                    }}
                >
                    <LoginHeader title={t('signup.title')+''} subtitle={t('signup.subtitle')} />
                    { renderCardContent() }   
                </Card>
            </Grid>
        </Grid>
       
        <Backdrop open={state.loading} sx={{ zIndex: 2 }}>
            <CircularProgress color="primary" />
        </Backdrop>
    </>);
}

export default  withTranslation('common')(withRouter(Register))