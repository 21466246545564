import {
    Box,
    CardHeader,
    Typography
} from "@mui/material";
import logo from '../assets/images/single-logo.png';

export interface ILoginHeader {
    title: string;
    subtitle?: string;
}

export const LoginHeader = ({title, subtitle}: ILoginHeader) => {
    
    return  <CardHeader 
        title={
            <div className="login-header">
                <Box
                    component="img"
                    src={logo}
                    sx={{ width: '50px', height: '60px', pt: '16px' }}
                />
                <Typography gutterBottom variant="h4" component="h4" sx={{ margin: '5px', fontSize: '22px' }} >{title}</Typography>
                { subtitle && <Typography gutterBottom variant="body2" sx={{ textAlign: 'center' }}>{ subtitle }</Typography> }
            </div>
        }
        sx={{
            px: 2
        }}
    />
}