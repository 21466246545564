import { createSlice } from "@reduxjs/toolkit";

export const messageSlice = createSlice({
  name: 'message',
  initialState: {},
  reducers: {
    sendLogedin: (state, action) => {
      window.parent?.postMessage({
        source: 'login',
        target: '9assetApp',
        type: 'logged-in',
        data: action.payload
      }, '*');
      console.log('send logged-in');
    },
    sendSignupComplete: (state, action) => {
      window.parent?.postMessage({
        source: 'login',
        target: '9assetApp',
        type: 'logged-in',
        data: action.payload
      }, '*');
      console.log('send logged-in');
    }
  }
});

export const { sendLogedin } = messageSlice.actions;
export const messegeReducer = messageSlice.reducer;