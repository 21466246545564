import {
    useLocation,
    useNavigate,
    useSearchParams,
    Location,
    NavigateFunction,
} from "react-router-dom";

export interface RouteComponentProps {
    location: Location<any>;
    navigate: NavigateFunction;
    params: URLSearchParams;
}
export const withRouter = (Component: any) => {
    const Wrapper = (props: any) => {
        const location = useLocation();
        const navigate = useNavigate();
        const [params, ] = useSearchParams();
        return (
        <Component
            {...props}
            location={location}
            navigate={navigate}
            params={params}
        />
        );
    };

    return Wrapper;
};
