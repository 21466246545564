import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyC8Yi6jarHdVaGs9bkwZhfPvQ_do0eZvuE",
    authDomain: "directed-radius-140921.firebaseapp.com",
    projectId: "directed-radius-140921",
    storageBucket: "directed-radius-140921.appspot.com",
    messagingSenderId: "866569999379",
    appId: "1:866569999379:web:5fb234f0b59c777c6e1be6"
  };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
export const auth = getAuth(app);

// auth.onAuthStateChanged(async (user) => {
//     if (user) {
//         console.log(user);
//         localStorage.setItem('9asset_token', await user.getIdToken());

//         try {
//             const token =  await user.getIdToken();
//             const userInfo = (await axios.get(`${process.env.REACT_APP_BASE_URL_USER_SERVICE}/users`, { headers: { 'Authorization': `Bearer ${token}`} })).data;
//             localStorage.setItem(`9asset.userinfo`, JSON.stringify(userInfo));
//         } catch (error) {

//         }
//     } else {
//         console.log(user);
//         localStorage.removeItem('9asset_token');
//         localStorage.removeItem('9asset.userinfo');
//     }
// });