import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LoginHeader } from "../components/loginHeader";
import { auth } from "../firebase";
import { useSearchParams } from "react-router-dom";

export interface LogoutProps {

}

export interface LogoutState {

}

export const Logout = (props: LogoutProps) => {

    const { t, i18n } = useTranslation('common');
    const [search] = useSearchParams();
    
    useEffect(() => {
        performLogout();
    }, []);

    const performLogout = async () => {
        setTimeout(async () => {
            await auth.signOut();
            localStorage.removeItem('9asset_token');
            localStorage.removeItem('9asset.userinfo');
            setTimeout(() => { performRedirect() }, 1000);
        }, 300);
    }

    const performRedirect = () => {
        if (search && search.has('redirect')) {
            const url = decodeURIComponent(search.get('redirect') || '');
            window.location.href = url;
        } else {
            // this.props.history.push('/user');
            window.location.href = process.env.REACT_APP_HOME_URL || ''; //'https://my.9asset.com'
        }
    }

    
    return (
    <Grid container sx={{ maxWidth: 600, margin: '0 auto', padding: '4px', height: '100%' }}>
        <Grid item xs={12} container justifyContent="center" alignItems="center">
            <Card elevation={1} sx={{ margin: '10px auto', width: '100%', marginTop: '-40%' }} >
                <LoginHeader title={t('loggingout')} subtitle={''} />
                <CardContent>
                    <Grid container sx={{ padding: '10px 40px' }} justifyContent="center" alignItems="center">
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="body1" >{t('logingout')}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    </Grid>);
    
}

export default Logout;
